<template>
<v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">
<v-card tile  v-if='!bankdata' height="100%" width="450"  elevation="0" class="px-9 px-lg-12 mx-auto">
     <v-progress-linear absolute color='#004B55' height=10 value="86"></v-progress-linear>
    <v-col  class="pt-6 px-0 pb-0">
        <v-img 
            :src="require('@/assets/invezt.svg')"
            contain
            class="ml-n4 py-2 py-lg-0"
            width="110" 
            />
            <h2  class="mt-1" style="color:#004B55;font-size:16px;">Add Your Bank Account</h2>
            <p  class="mt-1" style="font-size:14px;font-weight:500" >Details wherein you are sole/primary holder</p>
    
        <v-row class="pa-4">
            <v-text-field  :disabled="bankverifystatus"
                validate-on-blur
                color='#004B55'
                 v-model="ifsc"
                label="IFSC Code"
                prepend-icon="mdi-credit-card"
                @keyup="ifsc=ifsc.toUpperCase(),ash=false"
                v-on:keyup="ifscValidation()"
                :error-messages="ifscErrors"
                @blur="$v.ifsc.$touch()"
                @input="ifscFetchDetails"
                counter="11"
                required
             ></v-text-field>
        
            <v-text-field :disabled="bankverifystatus"
                hide-spin-buttons
                type="number"
                v-model="accNo"
                color='#004B55'
                prepend-icon="mdi-bank"
                label="Bank Account Number"
                required
                 @keypress="ash=false"
                :error-messages="accErrors"
                @blur="$v.accNo.$touch()"
            ></v-text-field>
            
            <v-select v-if="!bankverifystatus"
            :items="accountTypeItems"
            v-model="accountType"
            prepend-icon=" mdi-card-account-details"
            color='#004B55'
             label="Account Type"
             required
            :error-messages="acctypeErrors"
            @blur="$v.accountType.$touch()"
            ></v-select>
      
        </v-row>

            <h2 v-if="bank_name" class="mt-2 ml-2" style="color:#004B55;font-size:16px;">Bank Details</h2>
            <div v-if="bank_name" class="mt-3">
                  <strong style="padding-left:8px"> {{ bank_name }} </strong>
                  <p style="font-size:12px;padding-left:8px">{{ bank_branch }} | {{ bank_micr}}</p>
            </div>
    </v-col>
         <v-col v-if="bankverifystatus" class="pb-16">
         <v-card class="ma-0 mb-6 " style="background-color:#424242;">
              <v-col class="pa-4">
                  <p class="mb-0 d-flex"> <span class="text-center mx-auto"><v-icon v-if="accstatus =='active'" color="#FFB900" x-large>mdi-account-check-outline</v-icon><v-icon  color="#FFB900" v-else x-large>mdi-account-cancel-outline</v-icon></span> </p>
                  <p class="mb-0" style="font-size:14px;text-align:center;color:white">Account Status : {{  accstatus}}</p>
                  <p class="mb-1" style="font-size:11px;text-align:center;color:white">Transaction Number : {{  tran_no }}</p>
                
                 <p v-if="!retrybank" class="mb-0" style="font-size:14px;text-align:center;color:white">Account Name : {{ bankUname }}</p>
                  
                  <v-row class="pa-0 pt-5 pb-2 px-3">
                        <v-btn v-if="bankverifystatus"
                          style="color:white;" color="#FFB900" 
                          class="font-weight-bold flex-nowrap"
                           block
                           @click="checkforbank()"
                          >{{ retrybank?"Retry":"Proceed"}}</v-btn>
                        <v-progress-linear color="#1976D2" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
                  
                  </v-row>
              </v-col>
        </v-card>               

    </v-col>


         <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
            <v-btn v-if="!bankverifystatus" :disabled='$v.$invalid || cardloader'
                block
                style="color: white"
                color="#FFB900"
                class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold" 
                @click="bankDetailUpdate()"
                >CONTINUE</v-btn>
                 <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
          </div>

      <v-overlay :value="cardloader" absolute opacity="0.9">  <!--cardloader-->
            <v-row>
              <v-progress-circular
                color="#FFB900"
                indeterminate
                size="65"
                width="6"
                class="mx-auto"
              ></v-progress-circular>
          </v-row>
          <v-row class="mt-6">
             <p class="mb-0">Be patient working with your Bank...</p>
          </v-row>
    </v-overlay>
</v-card>
</v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api';
  import { validationMixin } from 'vuelidate'
  import { required,minLength } from 'vuelidate/lib/validators'
   export default {
      mixins: [validationMixin],
      validations: {
         ifsc : { required },
         accNo : { required,minLength:minLength(8)  },
         accountType : { required },
         
         },
    data(){
      return {
             bankdata:false,
             accNo: null,
             ifsc: null,
             bank_name:null,
             bank_micr:null,
             bank_branch:null,
             accountTypeItems:['Saving Account','Current Account'],
             accountType:null,
             cardloader:false,
             ifscErrorDesc:false,
             ash:false,
             
             bankverifystatus:false,
             retrybank:false,
             bankUname:null,
             accstatus:null,
             tran_no:null,
              }
    },
    methods:{        
          checkforbank(){
              if(this.retrybank){
                this.bankverifystatus=false;
               // this.accNo=null
               // this.ifsc=null
               this.retrybank=false
              this.accountType=null
              }else{
                  this.$router.push({name:'exchange'})
              }

          },

        ifscValidation(){
                            //this.ifscErrorDesc=false
                            if (this.ifsc){
                                    var newVal = this.ifsc.replace('-','');
                                    //console.log("New Val:",newVal);
                                    if(this.ifsc.length > 11){
                                        this.ifsc = newVal.slice(0,11);
                                    }
                            }
                        },
        
           bankDetailUpdate(){
                          this.$v.$touch()
                            this.ash=true
                            // console.log("adasdasdasdsa=",this.ifsc ,this.accNo , this.bank_name)
                           if(this.ifsc && this.accNo && this.bank_name)  {
                               this.cardloader=true;    
                              this.$v.$reset()
                                var config = {
                                  method: 'post',
                                  url: api.api_url+'/bankDetails?mob='+atob(localStorage.getItem('hash'))+'&bankName='+this.bank_name+'&ifsc='+this.ifsc+'&accountNo='+this.accNo+'&micr='+this.bank_micr+'&branch='+this.bank_branch+'&bank_account_type='+this.accountType,
                                  headers: { }
                                };
                                var axiosThis = this 
                                axios(config)
                                .then(function (response) {
                                  // console.log("Main response == ",(response.data));
                                    axiosThis.cardloader=false
                                  //  axiosThis.$router.push({name:'exchange'}) 
                                    
                                     var resdata=response.data;
                                  if(resdata["data"]["status"]=="completed"){
                                     axiosThis.cardloader=false
                                      axiosThis.bankUname=resdata['data']["results"]["registered_name"]
                                      axiosThis.accstatus=resdata['data']["results"]["account_status"]
                                      axiosThis.tran_no=resdata['data']["utr"]
                                      axiosThis.accNo=resdata['data']["fund_account"]["bank_account"]["account_number"]
                                      axiosThis.ifsc=resdata['data']["fund_account"]["bank_account"]["ifsc"]
                                      axiosThis.bankverifystatus=true
                                      if(axiosThis.accstatus!='active'){
                                          axiosThis.retrybank=true;
                                          axiosThis.bankverifystatus=true
                                          //axiosThis.checkforbank()
                                      }
                                      // var data = response.data
                                      // axiosThis.ifsc = data['ifsc']
                                      // axiosThis.bank_name = data['bankName']
                                      // axiosThis.accNo = data['accNo']
                                      // axiosThis.bank_micr = data['micr']
                                      // axiosThis.bank_branch = data['branch']
                                      // axiosThis.accountType = data['bank_account_type']
                                      
                                      }

                                      else{
                                           axiosThis.cardloader=false
                                           axiosThis.bankverifystatus=true
                                           axiosThis.retrybank=true;
                                           //axiosThis.checkforbank()
                                      }
                                })
                                .catch(function (error) { 
                                  console.log(error);
                                  alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                });
                            }
                       },  

               async ifscFetchDetails(){
                              // console.log("Ifsc async :"+this.ifsc.length,this.ifsc);

                              if (this.ifsc.length >= 11 ){
                                 
                                  var ifsvc = this.ifsc;
                                  if(this.ifsc.length > 11){
                                    ifsvc = this.ifsc.slice(0,11);
                                      this.$v.$reset()
                                  }
                                  // console.log("IFSC :",ifsvc);

                                  const axios = require('axios');
                                  var config = {
                                    method: 'get',
                                    url: 'https://ifsc.razorpay.com/'+ifsvc,
                                  };

                                  var axiosThis=this
                                  await axios(config)
                                  .then(function (response) {
                                   // console.log("side resp === ",response.data );
                                    var req_data =response.data;
                                   // axiosThis.$v.$reset();
                                    var bank_name = req_data['BANK'];
                                    var micr = req_data['MICR'];
                                    var branch =req_data['BRANCH']+", "+req_data['CITY']+', '+req_data['STATE'];
                                    axiosThis.bank_name = bank_name;
                                    axiosThis.bank_branch = branch;
                                    axiosThis.bank_micr =  micr;
                                    axiosThis.ifscErrorDesc = false;
                                    axiosThis.cardloader=false;
                                    axiosThis.$v.$reset()
                                  })
                                  .catch(function (error) {
                                    console.log("IFSC errorr catched!"+error);
                                    axiosThis.ifscErrorDesc =true;
                                    axiosThis.ifscErrors;
                                    alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                   });
                              }
                              else{
                                    this.bank_name = null;
                                    this.bank_branch = null;
                                    this.bank_micr =  null;
                                  }
                        },
    },
    computed:{
                // buttonEnabled() {
                //   if(this.ifsc && this.accNo && this.bank_name){
                //     return this.cardloader=true;
                //   }else{
                //     return this.cardloader=false;
                //   }
                // },


                  ifscErrors () {
                            const errors = []
                            if(this.ifsc){
                              if (this.ifsc.length != 11 && !this.ash){
                                this.$v.$reset()
                                }
                            }
                            // console.log("Ifsc Error : ",this.ifscErrorDesc)
                            if (this.ifsc)
                            {
                              if (this.ifsc.length != 11)
                              {
                                errors.push('Please enter 11 Digit IFSC Code')
                              }
                            }
                            if(this.ifscErrorDesc){
                                errors.push('Enter IFSC is invalid')
                                return errors
                                
                            }
                            else{
                              // console.log(this.$v.ifsc.$dirty,this.$v.ifsc.required);
                              if (!this.$v.ifsc.$dirty) return errors
                              !this.$v.ifsc.required && errors.push('IFSC code required')
                              return errors
                            }
                          },
                  accErrors () {
                            const errors = []
                          
                              if (!this.ash){
                                this.$v.$reset()
                                
                            }
                            // console.log(this.$v.accNo.$dirty,this.$v.accNo.required);
                            if (!this.$v.accNo.$dirty) return errors
                            !this.$v.accNo.minLength && errors.push('invalid number')
                            !this.$v.accNo.required && errors.push('Account number required')
                            return errors
                          },
                  rules() {
                          // console.log(this.exchangeSelect,this.exchangeSelect.length);
                          if(this.exchangeSelect.length == 0){
                            return [false]
                          }
                          else{
                            return [true]
                          }
                          //return this.exchangeSelect.length > 0 || "At least one item should be selected"
                    
                          },
            acctypeErrors () {
                          const errors = []
                          if (!this.$v.accountType.$dirty) return errors
                          !this.$v.accountType.required && errors.push('Mandatory Field')
                          return errors
                        },


    },
    mounted(){
              var config = {
                method: 'post',
                url: api.api_url+'/bankDetails?mob='+atob(localStorage.getItem('hash')),
                headers: { }
              };
             //  this.cardloader=true
              var axiosThis = this 
              axios(config)
              .then(function (response){
                console.log("Main response",(response.data));
                  axiosThis.cardloader=false
                 // var data = response.data

                  var resdata=response.data;
                 axiosThis.bankverifystatus=false

              if("data" in resdata){
                if(resdata["response"]=="yes"){
                  axiosThis.cardloader=false
                  axiosThis.bankUname=resdata['data']["results"]["registered_name"]
                  axiosThis.accstatus=resdata['data']["results"]["account_status"]
                  axiosThis.tran_no=resdata['data']["utr"]
                  axiosThis.accNo=resdata['data']["fund_account"]["bank_account"]["account_number"]
                  axiosThis.ifsc=resdata['data']["fund_account"]["bank_account"]["ifsc"]
                  axiosThis.bankverifystatus=true
                  
                  }
                  else{
                    axiosThis.cardloader=false
                    axiosThis.bankUname=resdata['data']["results"]["registered_name"]
                    axiosThis.accstatus=resdata['data']["results"]["account_status"]
                    axiosThis.tran_no=resdata['data']["utr"]
                    axiosThis.accNo=resdata['data']["fund_account"]["bank_account"]["account_number"]
                    axiosThis.ifsc=resdata['data']["fund_account"]["bank_account"]["ifsc"]
                    axiosThis.retrybank=true;
                    axiosThis.bankverifystatus=true
                      //axiosThis.checkforbank()
                  }

                }
              })
              .catch(function (error) { 
                console.log(error);
                alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
              });
    }
  }
</script>

