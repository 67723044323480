<template>
  <v-app >
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
    name: 'App',    
    metaInfo() {
        return { 
            title: "E-Kyc – Invezt",
            meta: [
                { name: 'description', content: 'Complete the online registration with Invezt'},
                { property: 'og:title', content: "E-Kyc – Invezt"},
                { property: 'og:site_name', content: 'Invezt'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>
