import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import email from '../views/email.vue'
import pan from '../views/pan.vue'
import pricing from '../views/pricing.vue'
import Aadhar from '../views/aadhar.vue'
import personal from '../views/personal.vue'
import nominee from '../views/nominee.vue'
import bank from '../views/bank.vue'
import exchange from '../views/exchange.vue'
import fileupload from '../views/fileupload.vue'
import ipv from '../views/ipv.vue'
import Inveztadmindash from '../views/admindash.vue'
import submit from '../views/submitekyc.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login  
  },

  {
    path: '/email',
    name: 'email',
    component: email
  },

  {
    path: '/pan',
    name: 'pan',
    component: pan
  },

  {
    path: '/pricing',
    name: 'payment',
    component: pricing
  },
 
  {
    path: '/aadhar',
    name: 'Aadhar',
    component: Aadhar
  },

  {
    path: '/personal',
    name: 'personal',
    component: personal
  },

  {
    path: '/nominee',
    name: 'nominee',
    component: nominee,
  },

  {
    path: '/bank',
    name: 'bank',
    component: bank,
  },

  {
    path: '/exchange-selection',
    name: 'exchange',
    component: exchange,
  },
 
  {
    path: '/fileupload',
    name: 'fileupload',
    component: fileupload,
  },

  {
    path: '/ipv',
    name: 'ipv',
    component: ipv,
  },

  {
    path: '/admin/Inveztdashboard',
    name: 'Inveztadmindash',
    component: Inveztadmindash,
  },

  {
    path: '/submit',
    name: 'submit',
    component: submit
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.go(1)
export default router
