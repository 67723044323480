<template>
    <v-container class="pa-0"  fluid fill-height style="background-color:#F0F5FA">
<v-card height="100%" tile width="450" elevation="0" class="px-9 px-lg-12 mx-auto">
   <v-progress-linear absolute color='#004B55' height=10 value="30"></v-progress-linear>
  
    <v-col  v-if='!panOtp_screen' class="pt-6 px-0 pb-0" >
                  
                  <v-img
                :src="require('@/assets/invezt.svg')"
                class="ml-n4"
                contain
                width="110"
                />
                <h2 class="mt-1" style="color:#004B55;font-size:16px;">Add Your PAN</h2>

        <v-row no-gutters class="mt-4 mt-lg-16">
          <v-col cols=1>
                <v-img v-if="!panOtp_screen"
                  :src="require('@/assets/pan.svg')"
                  contain
                  class="mt-4"
                  height="42"
                  max-width="27"
                  />

                  <v-img v-if="panOtp_screen"
                  :src="require('@/assets/pan.svg')"
                  contain
                  class="mt-4"
                  height="42"
                  max-width="27"
                  />
            </v-col>
            <v-col>
                  <v-text-field  v-if="!panOtp_screen"  
                                counter="10"
                                color='#004B55'
                                v-model="panInput"
                                @keyup="panInput ? panInput=panInput.toUpperCase():''"
                                @keyup.enter="panFetch()"
                                @keypress="panNumberValidation($event),ash=false"
                                class="ml-4"
                                label="PAN Number"
                                :error-messages="panErrors"
                                @blur="$v.panInput.$touch()"
                            >  <!--  @input="$v.panInput.$touch()" -->
                  </v-text-field>
                
            </v-col>

        </v-row>
           <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
                <v-btn block style="color:white;" color="#FFB900"  @click="panFetch(),$v.panInput.$touch()" :disabled="$v.panInput.$invalid || cardloader" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold">CONTINUE</v-btn>
                <v-progress-linear v-if="!panOtp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
            </div>
    </v-col>

    <v-col v-if="panOtp_screen">
          <v-img
          :src="require('@/assets/invezt.svg')"
          class="ml-n4"
          contain
          width="110"
          />
          <h2 class="mt-1" style="color:#004B55;font-size:16px;">Your PAN Details</h2>

        <v-row no-gutters class="mt-4 mt-lg-16">
              <v-col cols=1>
              <v-img v-if="panOtp_screen"
              :src="require('@/assets/pan.svg')"
              contain
              class="mt-4"
              height="42"
              max-width="27"
              />
              </v-col>
              <v-col>
                  <v-text-field disabled
                              color='#004B55'
                            v-model="panInput"
                            class="ml-4"
                            label="PAN"
                            >
                  </v-text-field>
            
            </v-col>
        </v-row>

         <v-card class="ma-0 mb-6 mt-12 " style="background-color:#004B55;">
              <v-col class="pa-4">
                  <h2 style="font-size:14px;text-align:center;font-weight:400;color:white">Your name will be taken as mentioned in the income tax</h2>
                  <h2 class="mt-2" style="font-size:14px;text-align:center;color:white">{{  name  }}</h2>
                  <v-row class="pa-0 pt-5 pb-2 px-3">
                      <v-btn small  style="color:white;" color="#FFB900" class="font-weight-bold"  @click="change()" href="#">CHANGE</v-btn>
                      <v-spacer></v-spacer>
                    <router-link
                        :to="'/aadhar'"
                        class="text-decoration-none"
                    >
                        <v-btn
                          style="color:white;" color="#FFB900" 
                          class="font-weight-bold"
                          small
                        >PROCEED</v-btn>
                        <v-progress-linear v-if="panOtp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
                    </router-link>

                  </v-row>
              </v-col>
        </v-card>

    </v-col>


      <v-snackbar class="mb-16 text-justify" :timeout="snackbartimeout" absolute v-model="snackbar">
          {{ toasttext }}<br/>
          <!-- <p  class="caption mb-0" >*{{toasttextsupport}}</p> -->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
   
</v-card>

 </v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api';
  import { validationMixin } from 'vuelidate'
  import { required,alphaNum,helpers} from 'vuelidate/lib/validators'
  const alpha = helpers.regex('alpha', /[A-Z]{5}[0-9]{4}[A-Z]{1}/)
  export default {
        mixins: [validationMixin],
        validations: {
                panInput : {required,alphaNum,alpha},
                panFile:{required},
                
        },
        data(){
          return {
              panDetaildisp: true,
              panInput:'',
              panOtp_screen:false,
              errorDescPAN:false,
              errorDescPANalready:false,
              resppan:'',
              name:null,
              cardloader:false,
              ash:false,
              sample:'',
              toasttext:null,
              toasttextsupport:null,
              snackbartimeout:null,
              snackbar:false,
          };
        },
      methods:{
          change(){             
                    this.$v.$touch()
                    this.ash=true
                    if(this.panInput){  
                        
                        var axiosThis = this
                      var json_data = {
                                mobile: atob(localStorage.getItem('hash')),
                                    pan: axiosThis.panInput,
                            };
                        var config = {
                        method: 'post',
                        url:api.api_url+'/changeSignupPAN',
                        headers: {'Content-Type': 'application/json'},
                        data:JSON.stringify(json_data)
                      };

                        axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                          var responsePANfetch =  response.data
                          console.log(responsePANfetch)
                          if (responsePANfetch["data"] == "changed"){
                              axiosThis.panOtp_screen=false;
                              axiosThis.panInput=''
                          }else if (responsePANfetch["data"] == "invalid"){
                            axiosThis.toasttext="error occured in PAN change"
                            axiosThis.toasttextsupport=" Please contact client support : 93 8010 8010 or email us at support@zebuetrade.com"
                            axiosThis.snackbar=true
                            axiosThis.snackbartimeout=-1    
                          }

                        })
                        .catch(function (error) {
                          console.log(error);  
                        });
                    }  

                          },
 
          panNumberValidation($event){
                          if (this.panInput){
                                if (this.panInput.length >=10) {               
                                    $event.preventDefault();                
                                  }
                          }
                  },
          panFetch(){
                    this.$v.$touch()
                    this.ash=true
                    if(this.panInput && !this.$v.panInput.$invalid){  
                        this.cardloader =true
                        this.$v.$reset()
                        // var config = {
                        //   method: 'post',
                        //   url: api.api_url+'/panCheck?PAN='+this.panInput+'&mob='+atob(localStorage.getItem('hash')), 
                        //   headers: { }
                        // };
                        var axiosThis = this
                         var json_data = {
                                        mobile: atob(localStorage.getItem('hash')),
                                           pan: axiosThis.panInput,
                                    };
                                var config = {
                                method: 'post',
                                url:api.api_url+'/getSignupPAN',
                                headers: {'Content-Type': 'application/json'},
                                data:JSON.stringify(json_data)
                              };
                        axios(config)
                        .then(function (response) {
                          console.log(JSON.stringify(response.data));
                          var responsePANfetch =  response.data
                          if (responsePANfetch["Name"] == 'No Data'){
                            axiosThis.cardloader =false
                            // console.log("Not verified")
                            axiosThis.errorDescPAN=true
                            axiosThis.$v.$reset()
                            setTimeout(() => {axiosThis.errorDescPAN=false}, 4000);
                            axiosThis.snackbar=true
                            axiosThis.toasttext="Contact our Customer support for more help +91 93 8010 8010"
                          }
                          else if (responsePANfetch["Name"] != 'No Data' && responsePANfetch["clpan"] != ''){
                            // console.log("PAN Verified")
                            axiosThis.errorDescPANalready=true
                            axiosThis.$v.$reset()
                            axiosThis.resppan=responsePANfetch["clpan"]
                            //setTimeout(() => {axiosThis.errorDescPAN=false}, 4000);
                            axiosThis.toasttext="Contact our Customer support for more help +91 93 8010 8010"
                            axiosThis.cardloader =false
                            axiosThis.snackbar=true
                            axiosThis.snackbartimeout=-1
                            axiosThis.name=responsePANfetch["Name"]
                            axiosThis.panOtp_screen=false;
                            //axiosThis.panDetaildisp = false
                          }
                          else{
                            // console.log("PAN Verified")
                            axiosThis.cardloader =false
                            axiosThis.name=responsePANfetch["Name"]
                            axiosThis.panOtp_screen=true;
                          }
                        })
                        .catch(function (error) {
                          console.log(error);  
                          alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                        });
                    }  
                },
                },
        computed:{     
              panErrors () {
                        const errors = [] 
                        
                          if (!this.ash){
                              this.$v.$reset()
                            }
                        if(this.errorDescPAN){
                            errors.push('Enter PAN is invalid')
                          
                            return errors 
                        } if(this.errorDescPANalready){
                            errors.push('PAN is already registered with us ('+this.resppan+')')
                          
                            return errors 
                        }
                        else{
                          // console.log(this.$v.panInput.$dirty,this.$v.panInput.required);
                          if (!this.$v.panInput.$dirty) return errors
                          !this.$v.panInput.required && errors.push('Mandatory Field')

                          !this.$v.panInput.alpha && errors.push('Enter a valid PAN')
                          return errors
                        }
                      },

                },
            mounted(){
                        // var config = {
                        //   method: 'post',
                        //   url: api.api_url+'/panCheck?mob='+atob(localStorage.getItem('hash')),
                        //   headers: { }
                        // };
                        var axiosThis = this
                          var json_data = {
                                mobile: atob(localStorage.getItem('hash')),    
                              };
                        var config = {
                        method: 'post',
                        url:api.api_url+'/getSignupPAN',
                        headers: {'Content-Type': 'application/json'},
                        data:JSON.stringify(json_data)
                          };
                        axios(config)
                        .then(function (response) {
                            if(response.data["Name"] && response.data["PAN"]){
                              console.log(JSON.stringify(response.data));
                              // console.log("PAN Verified")
                              axiosThis.cardloader =false
                              axiosThis.name=response.data["Name"]
                              axiosThis.panInput = response.data["PAN"]
                              axiosThis.panOtp_screen=true;
                              //axiosThis.panDetaildisp = false
                              axiosThis.panFetchDetail = true
                              // axiosThis.$router.push({name:'Aadhar'})
                          }
                        })
                        .catch(function (error) {
                          console.log(error);
                          axiosThis.cardloader=false;
                          alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                        });
        }
        }
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
*{
  font-family: 'Inter', sans-serif;
}
</style>