<template>
  <v-container class="pa-0" fluid fill-height style="background-color: #f0f5fa">
    <v-card tile
      height="100%"
      max-width="450"
      elevation="0"
      class="px-9 px-lg-12 mx-auto">

 <v-progress-linear absolute color='#004B55' height=10 value="100"></v-progress-linear>
    <v-row  class="pt-6 px-0 pb-0">     
    <v-col cols=12 class="pb-0 pt-16">
         <v-img
          :src="require('@/assets/invezt.svg')"
          class="mx-auto "
          contain
          height='65'
          width="110"
        />
    </v-col>
    <v-col cols=12>
        <h2
          class="flex text-center"
          style="color: #004b55; font-size:16px"
        >
       Wohoo!
        </h2>
          <p class="mt-2 flex text-center" style="font-size: 14px; font-weight:500">
        Congratulations! Your application has been submitted
        </p>

            <!-- <span class="text-center"> -->   
                <v-row class="justify-center pt-2">
                 <v-icon color='#004b55' center size=150>mdi-check-circle</v-icon>
                   </v-row> 
            <!-- </span> -->

       <h2
          class="mt-5 pt-2"
          style="
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
          ">
              Your application is currently under review. You will
              receive an update on your registered email within the
              next 48 hours.
        </h2>
      </v-col>

        </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    Otp_screen: false,
     genders: [
        'Male',
        'Female',
        'Other',
      ],
  }),
  methods: {
    open() {
      this.Otp_screen =true ; //!this.Otp_screen
    },
  },

   mounted(){  
              if(atob(localStorage.getItem('hash'))){
                  localStorage.removeItem('hash')
              }
              history.pushState(null, null, location.href);
              window.onpopstate = function () {
              history.go(1);  
          };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
* {
  font-family: "Inter", sans-serif;
}
</style>