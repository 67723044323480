<template>
<v-container class="pa-0"  fluid fill-height style="background-color:#F0F5FA">
  
<v-card height="100%" tile width="450" elevation="0" class="px-9 px-lg-12 mx-auto">
  <v-progress-linear absolute height=10 color='#004B55' value=2></v-progress-linear>
   <v-col class="pt-6 px-0 pb-0" >
    <v-img
        :src="require('@/assets/invezt.svg')"
        class="ml-n4"
        contain
        width="110"
        />
          <h2  v-if="!Otp_screen" class="mt-1" style="color:#004B55;font-size:16px;">Open Your Demat & Trading Account</h2>
          <h2 v-if="Otp_screen" class="mt-1" style="color:#004B55;font-size:16px;">Verify Your Mobile Number</h2>
          <p v-if="!Otp_screen" class="mt-1" style="font-size:14px;font-weight:500" >Kick-start your Investment Journey in Minutes</p>
          <p v-if="Otp_screen" class="mt-1" style="font-size:14px;" >We sent an OTP to <strong style="color:#004B55;">+91 {{mobile_num }} </strong><a style="text-decoration:none;color:#1976d2;font-size:12px;" href=""><b>change</b></a></p>

 <!-- <v-row><pre>{{$v}}</pre></v-row> -->


      <v-row no-gutters class="mt-4 mt-lg-16">
      <v-col cols=1>
            <v-img v-if="!Otp_screen"
            :src="require('@/assets/mob.svg')"
            contain
            class="mt-4"
            height="35"
            max-width="27"
            />
            <v-img v-if="Otp_screen"
            :src="require('@/assets/sec.svg')"
            contain
            class="mt-2"
            height="48"
            max-width="27"
            />
        </v-col>

                <!-- <pre>{{$v}}</pre>  -->
         <v-col>   
                <v-text-field  v-if="!Otp_screen"  
                          required
                          type="number"
                          hide-spin-buttons
                          color='#004B55'
                          v-model="mobile_num"
                          @keypress="phonevalidation($event),ash=false"
                          prefix="+91"  
                          counter="10"
                          class="ml-4 inputPrice mx-2"
                          label="Mobile number"
                          tabindex="1"
                          style="white-space: nowrap;"
                          :error-messages="numberErrors"
                          @keyup.enter="verifyMobile()"
                          @blur="$v.mobile_num.$touch()"
                          > <!--  @input="$v.mobile_num.$touch()" -->

                </v-text-field>
                <v-otp-input v-if="Otp_screen"
                          plain
                          color='#004B55'
                          v-model="mobile_otp"
                          label="Enter Mobile OTP"
                          length="4"
                          class="ml-4"
                          @keypress="OtpValidation(),ash=false"
                           @keyup.enter="OtpValidation()"
                          :error-messages="otpErrors"
                          @finish="$v.mobile_otp.$touch(),otpVerify()"                
                          required
                          type="number"
                ></v-otp-input> <!--  @input="$v.mobile_otp.$touch()" -->
                        <div v-if="Otp_screen" style="margin-top:-8px"><span style="font-size:13px;padding-left:20px;color:red;">{{otpErrors[0] || errortext }}</span></div>   <!-- errortext -->
          </v-col>
          </v-row>   <!-- otpbutton -->

        

           <p style="color:#919090;font-size:12px;line-height:20px;text-align:justify;" v-if="!Otp_screen" class="mt-5">On submitting your contact details, you authorize ZEBU / Invezt to call or text you for the purpose of account opening even though you may be registered on DND.</p>
            <p v-if="timerEnabled" style="text-align:center;font-size:12px;color:#919090;padding-top:15px;">{{ timerCount}} Sec</p>
            
            <p v-if="resendText" style="text-align:center;font-size:12px;color:#919090;padding-top:15px;margin-bottom:0px">Didn't receive OTP? <a @click="mobOtpResend()" ><b style="color:#1976d2"> resend</b></a>
            </p>
             <v-progress-linear v-if='resendloader'  :active="resendloader"
                    style="width:160px"
                    indeterminate
                    color="#004B55"
                    class="mb-0 mx-auto"
                  ></v-progress-linear>
    </v-col>

     <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
          <v-btn v-if="!Otp_screen" block style="color:white;" color="#FFB900" :disabled='$v.mobile_num.$invalid || cardloader' opacity=1 @click="verifyMobile(),$v.mobile_num.$touch()" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold">Get Started</v-btn>
          <v-progress-linear v-if="!Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
          
          <v-btn v-if="Otp_screen" block style="color:white;" color="#FFB900" :disabled='$v.mobile_otp.$invalid || cardloader'  @click="otpVerify()" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold" >VERIFY OTP</v-btn>
           <v-progress-linear v-if="Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
      </div>


</v-card>
<v-snackbar class="mb-16" v-model="snackbar" color="#002d33">
      <b>{{ toasttext }}</b>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#FFB900"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
         
 </v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api';
  import { validationMixin } from 'vuelidate'
  import { required,maxLength,numeric,minValue,minLength} from 'vuelidate/lib/validators'
  //  const matchNumber=match(/^[6-9][0-9]{9}$/)
  export default {
     mixins: [validationMixin],
          validations: {
          mobile_num: { required,maxLength:maxLength(10),numeric,minValue:minValue(6000000000)},
           mobile_otp: { required,minLength:minLength(4),numeric},
          
          },


    data(){
      return {
          mobile_num:'',
          mobile_otp:'',
          errorOTPDisp:false,
          showpassword:false,
          Otp_screen:false,
          cardloader:false,
          resendloader:false,
          toasttext:null,
          errortext:'',
          resendText:false,
          snackbar:false,
          snackbartimout:null,
          timerCount:60,
          timerEnabled:false,
          otpbutton:true,
          ash:false,         
      };
    },
    methods:{

               phonevalidation($event) {
                                //console.log("$event.keyCode==",$event.keyCode,$event); //will display the keyCode value
                                //console.log("$event.key==",$event.key); //will show the key value
                                var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                              
                                if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) ) {   // && keyCode !== 46  this if for dot key
                                    $event.preventDefault();
                                 }
                               
                                 if (this.mobile_num.length >= 10) {               
                                    $event.preventDefault();                
                                  }
                            
                              },

              OtpValidation(){
                              if(this.mobile_otp){ 
                                this.errorOTPDisp = false;
                                
                                var newVal = this.mobile_otp.replace('-','');
                                // console.log("New Val:",newVal,);
                                if(newVal.length > 4){
                                    this.mobile_otp = newVal.slice(0,4);
                                }
                              }
                            },
              verifyMobile(){
                                this.$v.$touch()
                                this.ash=true
                                var hash = new Date().toLocaleString()
                               
                                localStorage.setItem('sesslog',btoa(hash))
                                if (this.mobile_num )
                                { 
                                    if (this.mobile_num.length == 10 && !this.$v.mobile_num.$invalid){  
                                        this.cardloader = true
                                         var axiosThis = this
                                          this.$v.$reset()
                                        var json_data={
                                                "mobile":axiosThis.mobile_num,
                                                  "login_type":"signup"
                                                  }
                                        var config = {
                                          method: 'post',
                                          url:api.api_url+'/getSignupMobile',
                                          headers: {'Content-Type': 'application/json'},
                                          data:JSON.stringify(json_data)
                                        };
                                        axios(config)
                                        .then(function (response) {
                                                        console.log("mobile send :" ,response.data)
                                                        var data=response.data
                                                        if(data['data']=='Client with this details already exists'){
                                                          axiosThis.toasttext =data['data'];
                                                          axiosThis.snackbar = true;
                                                          axiosThis.snackbartimout=-1
                                                          axiosThis.Otp_screen = false;
                                                          axiosThis.timerEnabled = false;
                                                          axiosThis.cardloader = 'loading'
                                                          axiosThis.cardloader=false
                                                        }
                                                         else if(data['data']=='You are already registered with different flow.'){
                                                        axiosThis.toasttext = 'You are already registered with us in different form.';
                                                        axiosThis.snackbar = true;
                                                         axiosThis.snackbartimout=-1
                                                        axiosThis.Otp_screen = false;
                                                        axiosThis.timerEnabled = false;
                                                        axiosThis.cardloader = 'loading'
                                                        // console.log(JSON.stringify(response.data));
                                                        axiosThis.cardloader=false
                                                        }
                                                        else if(data['data']=='OTP has been sent sucessfully'){
                                                            axiosThis.toasttext = 'OTP Sent';
                                                            axiosThis.snackbar = true;
                                                            axiosThis.snackbartimout=5000
                                                            axiosThis.Otp_screen = true;
                                                            axiosThis.timerEnabled = true;
                                                            axiosThis.cardloader = 'loading'
                                                            // console.log(JSON.stringify(response.data));
                                                            axiosThis.cardloader=false
                                                        }
                                                        // axiosThis.toasttext = 'OTP Sent';
                                                        // axiosThis.snackbar = true;
                                                        // axiosThis.Otp_screen = true;
                                                        // axiosThis.timerEnabled = true;
                                                        // axiosThis.cardloader = 'loading'
                                                        // // console.log(JSON.stringify(response.data));
                                                        // axiosThis.cardloader=false
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                          axiosThis.cardloader=false
                                          alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                        });
                                    }
                                }
                                else{
                                  this.cardloader=false;
                                }


                            },
                    mobOtpResend(){        
                                   this.resendloader=true;
                                   var axiosThis = this
                                    var json_data={
                                             "mobile":axiosThis.mobile_num,
                                              "login_type":"signup"
                                          }
                                        var config = {
                                          method: 'post',
                                          url:api.api_url+'/getSignupMobile',
                                          headers: {'Content-Type': 'application/json'},
                                          data:JSON.stringify(json_data)
                                        };
                                    // var config = {
                                    //   method: 'post',
                                    //   url: api.api_url+'/resendmobotp?mob='+this.mobile_num+'&hash='+localStorage.getItem('sesslog'),
                                    //   headers: { }
                                    // };
                                   
                                    axios(config)
                                    .then(function () {
                                      axiosThis.toasttext = 'OTP Resent';
                                      axiosThis.snackbar = true;
                                      axiosThis.timerEnabled =true
                                      axiosThis.timerCount = 60
                                      axiosThis.resendText=false
                                      axiosThis.resendloader=false;
                                      // console.log(JSON.stringify(response.data));
                                    })
                                    .catch(function (error) {
                                      console.log(error);
                                      alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                    });
              },
              otpVerify(){
                 
                          var url = new URL(window.location.href);
                          var cref = url.searchParams.get("ref");
                          //console.log("url_string : ",url_string,"param : ",cref,api.api_url);
                          sessionStorage.setItem("ref",cref)
                          this.$v.$touch()
                          this.ash=true
                          this.cardloader=true
                          this.errorOTPDisp=false;
                          // console.log("mobile otp ",typeof(this.mobile_otp))
                          if(this.mobile_otp){ 
                                        if(this.mobile_otp.length == 4 && !this.$v.mobile_otp.$invalid){
                                            this.$v.$reset();
                                            var axiosThis = this
                                            var json_data={
                                                    "mobile":axiosThis.mobile_num,
                                                      "otp":axiosThis.mobile_otp,
                                                      "login_type":"signup"
                                                  }
                                            var config = {
                                                    method: 'post',
                                                    url:api.api_url+'/VerifySignupMobile',
                                                    headers: {'Content-Type': 'application/json'},
                                                    data:JSON.stringify(json_data)
                                                  };
                                            axios(config)
                                            .then(function (response) {
                                                var data = response.data
                                                console.log("data : ", (data));
                                                localStorage.setItem('hash',btoa(axiosThis.mobile_num))
                                              if (data['reject'] == 'yes') {
                                                        sessionStorage.setItem('reject', 'yes');
                                                        sessionStorage.setItem('bank_details', 'yes');
                                                        sessionStorage.removeItem('mobile_num');
                                                        console.log("rejected data  ")
                                                      //route fileupload
                                                        // localStorage.setItem('hash',btoa(axiosThis.mobile_num))
                                                        sessionStorage.setItem('rejectlist', JSON.stringify(data));
                                                        axiosThis.$router.push({name:'fileupload'})
                                                } 
                                              if(data['last_session']){

                                                if (data['last_session'] == 'signup'){
                                                    axiosThis.$router.push({name:'submit'})
                                                   }
                                                else if(data['last_session'] =="email_otp"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'email'})
                                                    }
                                                else if(data['last_session'] =="pan_card"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'pan'})
                                                    }

                                                else if(data['last_session'] =="payment"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'pricing'})
                                                    }
                                                else if(data['last_session'] =="aadhar"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'Aadhar'})
                                                    }
                                                else if(data['last_session'] =="personal_details"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'personal'})
                                                    }
                                                else if(data['last_session'] =="nominee"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'nominee'})
                                                    }
                                                else if(data['last_session'] =="bank_details"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'bank'})
                                                    }
                                                else if(data['last_session'] =="equity"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'exchange'})
                                                    }
                                                else if(data['last_session'] =="document"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'fileupload'})
                                                    }
                                                else if(data['last_session'] =="document"){
                                                    axiosThis.cardloader=false;
                                                    axiosThis.$router.push({name:'ipv'})
                                                    }
                                                else{
                                                      axiosThis.$router.push({name:'submit'})
                                                    }
                                            }else{
                                                 if (data['email'] == 'yes'){  
                                                    axiosThis.cardloader=false;
                                                    axiosThis.errorOTPDisp = false
                                                    axiosThis.errortext=''
                                                    axiosThis.toasttext = "OTP Verified";
                                                    axiosThis.snackbar = true;
                                                    localStorage.setItem('hash',btoa(axiosThis.mobile_num))
                                                    axiosThis.$router.push({name:'pan'})
                                                  }
                                                else if (data['data'] == 'OTP has been verified sucessfully'){
                                                    axiosThis.errorOTPDisp = false
                                                    axiosThis.errortext=''
                                                    axiosThis.toasttext = "OTP Verified";
                                                    axiosThis.snackbar = true;
                                                    localStorage.setItem('hash',btoa(axiosThis.mobile_num))
                                                    axiosThis.$router.push({name:'email'})
                                                }
                                                else if(data['data']=='Invalid OTP'){
                                                  axiosThis.mobile_otp=null
                                                  axiosThis.$v.$reset()
                                                  // console.log('OTP Verify False');
                                                  axiosThis.errortext = "OTP is Invalid!";
                                                  axiosThis.snackbar = false;
                                                  //axiosThis.errorOTPDisp=false
                                                  // axiosThis.cardloader=true
                                                  axiosThis.cardloader=false;
                                                  axiosThis.errorOTPDisp=true
                                                  axiosThis.otpErrors          
                                                }
                                            }
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                             alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                            });
                                        }
                          else{
                            
                            this.errorOTPDisp=false;
                            this.cardloader=false;
                            
                          }
                          }
                          else{
                            this.cardloader=false;
                          }
                 },
    },
    computed: {


            numberErrors () {
                              const errors = []
                              // console.log(this.$v.mobile_num.$dirty,this.$v.mobile_num.required,errors,this.ash);
                              if (this.mobile_num.length != 10 && !this.ash){
                                this.$v.$reset()
                                }
                              if (!this.$v.mobile_num.$dirty) return errors
                              
                                 !this.$v.mobile_num.required && errors.push('Mandatory Field') 
                                !this.$v.mobile_num.numeric || !this.$v.mobile_num.minValue && errors.push('Enter a valid number'); 
                             
                                return errors
                          },
              otpErrors () {
                        const errors = []
                        if(this.mobile_otp){ 
                          if (this.mobile_otp.length != 4 && !this.ash){
                                  this.$v.$reset()
                          }
                        }
                        // console.log("OTP Valid : ",this.errorOTPDisp,this.mobile_otp)
                        if(this.errorOTPDisp){
                            // var errortext='OTP is invalid'
                            // errors.push('Enter OTP is invalid')
                            return errors
                        }
                        else{
                            // console.log(this.$v.mobile_otp.$dirty,this.$v.mobile_otp.required);
                            if (!this.$v.mobile_otp.$dirty) return errors
                            !this.$v.mobile_otp.required && errors.push('Mandatory Field')
                            !this.$v.mobile_otp.minLength && errors.push('It should be 4 digit OTP')
                            return errors
                        }
                        
                     },

    },
    watch:{
            timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            timerCount: {
                handler(value) {

                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                    if (value == 0){
                      this.timerEnabled = false
                      this.resendText = true
                      this.resendloader=false
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
    },
    mounted() {
       // console.log("mounted : ",api.api_url)
           window.scrollTo(0,1);
          
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
*{
  font-family:'Inter', sans-serif;
}

/* Hide Home Header and Footer Mobile Only */
@media only screen and ( max-width: 768px ) {
.home #main-header { display:none !important }
.home #page-container { padding-top:0px !important }
.home #main-footer { display:none !important }}

@media only screen and (max-width: 990px) {
#header { display: none !important; }
}
</style>