<template>
    <v-container class="pa-0"  fluid fill-height style="background-color:#F0F5FA">
<v-card height="100%" tile width="450" elevation="0" class="px-9 px-lg-12 mx-auto">
   <v-progress-linear absolute color='#004B55' height=10 value=16></v-progress-linear>
            <v-col class="pt-6 px-0 pb-0" >
              <v-img
              :src="require('@/assets/invezt.svg')"
              class="ml-n4"
              contain
              width="110"
              />
          <h2 class="mt-1" style="color:#004B55;font-size:16px;">Verify Your Email Address</h2>
          <p v-if="Otp_screen" class="mt-1" style="font-size:14px;font-weight:500" >We sent an OTP to {{email}} <a style="text-decoration:none;color:#1976d2;font-size:12px;" href="/email">change</a></p>
              <v-row no-gutters class="mt-4 mt-lg-16  ">
                <v-col cols=1>
                  <v-img v-if="!Otp_screen"
                :src="require('@/assets/mail.svg')"
                contain
                class="mt-4"
                height="42"
                max-width="27"
                />
                <v-img v-if="Otp_screen"
                :src="require('@/assets/sec.svg')"
                contain
                class="mt-2"
                height="48"
                max-width="27"
                />
                </v-col>
                <v-col>
                <v-text-field  v-if="!Otp_screen" 
                          color='#004B55'
                          v-model="email"
                          class="ml-4"
                          :error-messages="emailErrors"
                          @keypress="ash=false"
                          @keyup.enter='verifyEmail()'
                          required
                          label="Email ID"
                          >  <!--  @input="$v.email.$touch()" -->
                </v-text-field>
                <v-otp-input v-if="Otp_screen"
                         v-model="otpemail"
                          plain
                          color='#004B55'
                          label="Enter Email OTP"
                          length="4"
                          class="ml-4"
                          @keypress="OtpeValidation(),ash=false"
                          :error-messages="eotpErrors"
                          @finish="$v.otpemail.$touch(),otpVerifyemail()"
                          counter="4"
                          required
                          type="number"
                  ></v-otp-input>
                     <div v-if="Otp_screen" style="margin-top:-8px"><span style="font-size:13px;padding-left:20px;color:red;">{{eotpErrors[0] || errortext}}</span></div>

              </v-col>
        </v-row>
            
            <p style="color:#919090;font-size:12px;line-height:20px;text-align:justify;" v-if="!Otp_screen" class="mt-5">On submitting your contact details, you authorize ZEBU to call or text you for the purpose of account opening even though you may be registered on DND.</p>
            <p v-if="timerEnabled" style="text-align:center;font-size:12px;color:#919090;padding-top:15px;">{{ timerCount}} Sec</p>
            <p v-if="resendText" style="text-align:center;font-size:12px;color:#919090;padding-top:15px;:margin-bottom:0px">Didn't receive OTP? <a @click="mobOtpResend()"> <b style="color:#1976d2">resend</b></a></p>
              <v-progress-linear v-if='resendloader'  :active="resendloader" style="width:160px" indeterminate color="#004B55" class="mb-0 mx-auto"></v-progress-linear>
            
          </v-col>

        <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
          <v-btn v-if="!Otp_screen" block style="color:white;" color="#FFB900"  @click="verifyEmail(),$v.email.$touch()" :disabled='$v.email.$invalid || cardloader' class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold">CONTINUE</v-btn>
              <v-progress-linear v-if="!Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
          
            <v-btn v-if="Otp_screen" block style="color:white;" color="#FFB900"  @click="otpVerifyemail()" :disabled='$v.otpemail.$invalid || cardloader' class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold">VERIFY OTP</v-btn>
             <v-progress-linear v-if="Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
         </div>

</v-card>
<v-snackbar class="mb-16" v-model="snackbar" color="#002d33">
      <b>{{ toasttext }}</b>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#FFB900"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
 </v-container>
</template>

<script>
    import axios from 'axios';
    import api from '@/api';
    import { validationMixin } from 'vuelidate'
    import { required, email , minLength } from 'vuelidate/lib/validators'
    export default {
            mixins: [validationMixin],
            validations: {
              email: { required, email },
              otpemail: { required ,minLength:minLength(4)},
    },
                          
    data(){
      return {
          email:null,
          emailDisp:false,
          emailOtpDisp:false,
          otpemail:null,
          erroreOTPDisp:false,
          showpassword:false,
          Otp_screen:false,
          loader:false,
          toasttext:null,
          resendText:false,
          snackbar:false,
          timerCount:60,
          timerEnabled:false,
          cardloader:false,
          errortext:'',
          resendloader:false,
            ash:false,    
      };
    },
    methods:{
              OtpeValidation(){
                        if(this.otpemail){ 
                          this.erroreOTPDisp = false;
                          var newVal = this.otpemail.replace('-','');
                          // console.log("New Val:",newVal);
                          if(newVal.length > 4){
                              this.otpemail = newVal.slice(0,4);
                              }
                          }
                      },
              verifyEmail(){                  
                    
                      this.$v.$touch()
                      this.ash=true
                      if(this.email){
                    
                      if (this.email.includes('@') && this.email.includes('.') )
                      { 
                        var axiosThis = this
                        this.$v.$reset()
                        this.cardloader = true;
                        var rmcode=sessionStorage.getItem('ref')
                         var rmcodee=null
                        if(rmcode !=null){

                            rmcodee=rmcode.toUpperCase()+'/reference'
                        }else{
                            rmcodee=''
                        }
                        var hash = new Date().toLocaleString()
                        localStorage.setItem('sesslog',btoa(hash))
                         var json_data = {
                                    mobile: atob(localStorage.getItem('hash')),
                                    email: axiosThis.email,
                                    rmcode: rmcodee ,
                                };
                        // var config = {
                        //   method: 'post',
                        //   url: api.api_url+'/email?mob='+atob(localStorage.getItem('hash'))+'&email='+this.email+'&hash='+btoa(hash),
                        //   headers: { }
                        // };
                         var config = {
                            method: 'post',
                            url:api.api_url+'/getSignupNameEmailRMCode',
                            headers: {'Content-Type': 'application/json'},
                            data:JSON.stringify(json_data)
                          };
                        axios(config)
                        .then(function (response) {
                            console.log("email response : ",JSON.stringify(response.data));
                            var data=response.data
                           if(data['data'] == 'Client with this details already exists'){
                                  axiosThis.cardloader= false
                                  axiosThis.toasttext = "Client with this details already exists";
                                  axiosThis.snackbar = true;
                                  axiosThis.Otp_screen = false;
                                  axiosThis.timerEnabled = false;
                                  axiosThis.$v.$reset()

                            }
                            else if(data['data'] == 'This email already exist.'){
                                  axiosThis.cardloader= false
                                  axiosThis.toasttext = "This email already exist.";
                                  axiosThis.snackbar = true;
                                  axiosThis.Otp_screen = false;
                                  axiosThis.timerEnabled = false;
                                  axiosThis.$v.$reset()
                            }
                            else{
                                axiosThis.cardloader= false
                                axiosThis.toasttext = "OTP sent to Email";
                                axiosThis.snackbar = true;
                                axiosThis.Otp_screen = true;
                                axiosThis.timerEnabled = true;
                                axiosThis.$v.$reset()
                            }


                        })
                        .catch(function (error) {
                            console.log(error);
                            axiosThis.cardloader = false;
                            alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                        });

                        this.otpdisp=true
                        this.timerEnabled =false
                        this.timerCount = 60
                        this.emailDisp = false
                        this.emailOtpDisp = true
                        //this.cardloader = false
                      }

                   }
              
                  },
            otpVerifyemail() {
                     
                      this.$v.$touch()
                       this.ash=true
                      if(this.otpemail){ 
                        if(this.otpemail.length == 4){
                           this.cardloader = true
                            this.$v.$reset()
                            var axiosThis = this

                            var json_data = {
                                    mobile: atob(localStorage.getItem('hash')),
                                    email: axiosThis.email,
                                     otp: axiosThis.otpemail,
                                };
                            var config = {
                            method: 'post',
                            url:api.api_url+'/verifySignupEmail',
                            headers: {'Content-Type': 'application/json'},
                            data:JSON.stringify(json_data)
                             };
                            // var config = {
                            //   method: 'post',
                            //   url: api.api_url+'/otpVerifyEmail?mob='+atob(localStorage.getItem('hash'))+'&email='+this.email+'&code='+this.otpemail+'&hash='+ localStorage.getItem('sesslog'),
                            //   headers: { }
                            // };
                            axios(config)
                            .then(function (response) {
                              console.log("verifty email otp : ",JSON.stringify(response.data));
                              var responseOTPemail = response.data
                              if (responseOTPemail['data'] != 'Invalid OTP'){
                                axiosThis.toasttext = "Email OTP is Verified";
                                axiosThis.snackbar = true;
                                axiosThis.erroreOTPDisp = false
                                axiosThis.cardloader = false
                                axiosThis.$router.replace({name:'pan'})
                                axiosThis.$v.$reset()
                              }
                              else{
                                axiosThis.otpemail = null
                                axiosThis.errortext = "Invalid OTP";
                                axiosThis.snackbar = false;
                                axiosThis.erroreOTPDisp = true
                                axiosThis.cardloader = false
                              }
                              // if (responseOTPemail['status'] == 'Verified'){
                              //   axiosThis.toasttext = "Email OTP is Verified";
                              //   axiosThis.snackbar = true;
                              //   axiosThis.erroreOTPDisp = false
                              //   axiosThis.cardloader = false
                              //   axiosThis.$router.replace({name:'pan'})
                              //   axiosThis.$v.$reset()
                              // }
                              // else{
                              //   axiosThis.otpemail = null
                              //   axiosThis.errortext = "Invalid OTP";
                              //   axiosThis.snackbar = false;
                              //   axiosThis.erroreOTPDisp = true
                              //   axiosThis.cardloader = false
                              // }
                            })
                            .catch(function (error) {
                              console.log(error);
                              axiosThis.cardloader = false
                              alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                            });
                        }
                        else{
                          //this.otpemail = null
                          this.erroreOTPDisp=false;
                            this.cardloader=false;
                          //this.cardloader = false
                         // this.errortext= "Invalid OTP";
                          
                        }
                      }
                      else{
                       this.cardloader=false;
                      }
              },
    },
    computed:{
                    emailErrors () {
                              if(this.email){
                              if (!this.ash){
                                this.$v.$reset()
                                }
                                }
                                  const errors = []
                                  if (!this.$v.email.$dirty) return errors
                                      !this.$v.email.email && errors.push('E-mail is not valid')
                                      !this.$v.email.required && errors.push('Mandatory Field')
                                      return errors
                                      },

                     eotpErrors () {
                                      if(this.otpemail){
                                     if (this.otpemail.length != 4 && !this.ash){
                                      this.$v.$reset()
                                    }
                                    }
                                  const errors = []
                                  if(this.erroreOTPDisp){
                                     // errors.push('OTP is invalid')
                                      return errors
                                  }
                                  else{ 
                                      // console.log(this.$v.otpemail.$dirty,this.$v.otpemail.required);
                                      if (!this.$v.otpemail.$dirty) return errors
                                      !this.$v.otpemail.required && errors.push('Mandatory Field')
                                      
                                      !this.$v.otpemail.minLength && errors.push('It should be 4 digit OTP')
                                      
                                      
                                      return errors
                                    }
                                },

    },
    watch:{
            timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            timerCount: {
                handler(value) {

                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                    if (value == 0){
                      this.timerEnabled = false
                      this.resendText = true
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
    },
    
    mounted(){
        // console.log(atob(localStorage.getItem('hash')))
    }


  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
*{
  font-family: 'Inter', sans-serif;
}
</style>