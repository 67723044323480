<template>
    <v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">
<v-card tile height="100%" width=450 elevation="0" class="px-9 px-lg-12 mx-auto">
   <v-progress-linear absolute color='#004B55' height=10 value="72"></v-progress-linear>
  <v-col  class="pt-6 px-0 pb-0 mb-16">
              <v-img 
                :src="require('@/assets/invezt.svg')"
                contain
                class="ml-n4 py-2 py-lg-0"
                width="110" 
                />
            <span class="ml-n8"><v-btn @click="$router.push({name:'personal'})" icon ><v-icon>mdi-arrow-left</v-icon></v-btn></span>
            <h2  class="mt-1 ml-1 d-inline" style="color:#004B55;font-size:16px;">Nominee Details</h2>

        <!-- <v-row><v-col><pre>{{$v.nominee1}}</pre></v-col><v-col> <pre>{{$v.nominee2}}</pre></v-col><v-col><pre>{{$v.nominee3}}</pre></v-col></v-row> -->
               



            <v-tabs dense class="mt-2"
                v-model="tab"
                fixed-tabs
                color='#004B55'
                >
                <v-tabs-slider class="mt-1" color="#004B55"></v-tabs-slider>
                <v-tab class="" style="text-transform: Capitalize;" @click="activetab='tab1'">1st Nominee</v-tab>
                <v-tab class="" style="text-transform: Capitalize;" @click="activetab='tab2'" :disabled="$v.nominee1.$invalid">2nd Nominee</v-tab>
                <v-tab class="" style="text-transform: Capitalize;" @click="activetab='tab3'" :disabled="$v.nominee1.$invalid || $v.nominee2.$invalid">3rd Nominee</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item class="py-3">
                            <v-row class="">
                                <v-col cols=12 class="pb-0">
                                    <v-text-field
                                        height="20"
                                        color='#004B55'
                                        dense
                                        label="Name"
                                        v-model="nominee1.n1_name"
                                        :error-messages="n1_nameErrors"
                                        required
                                        @blur="$v.nominee1.n1_name.$touch()"
                                    ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pb-0 pt-2">
                                        <v-text-field
                                            color='#004B55'
                                            dense
                                            label="Email"
                                            v-model="nominee1.n1_email"
                                            :error-messages="n1_emailErrors"
                                            required
                                            @blur="$v.nominee1.n1_email.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pb-0 pt-2">

                                    <v-text-field 
                                            color='#004B55'
                                            dense
                                            label="Mobile"
                                            type="number"
                                            hide-spin-buttons
                                            prefix="+91"  
                                            v-model="nominee1.n1_mobile"
                                            @keypress="n1phonevalidation($event)"
                                            :error-messages="n1_mobileErrors"
                                            required
                                            @blur="$v.nominee1.n1_mobile.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="">
                                <v-col class="pb-2 pt-2" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="PAN"
                                            @keyup="nominee1.n1_pan ? nominee1.n1_pan=nominee1.n1_pan.toUpperCase():''"
                                            @keypress="n1panNumberValidation($event)"
                                            v-model="nominee1.n1_pan"
                                            :error-messages="n1_panErrors"
                                            required
                                            @blur="$v.nominee1.n1_pan.$touch()"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="pb-2 pt-2" cols=6>

                                        <v-menu
                                            ref="n1_menu"
                                            v-model="n1_menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="280px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field dense
                                                        v-model="nominee1.n1_dob"
                                                        label="DOB"
                                                        color="#004B55"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @change="n1save"
                                                        :error-messages="n1_dobErrors"
                                                        required
                                                        @blur="$v.nominee1.n1_dob.$touch()" 
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="nominee1.n1_dob"
                                                no-title
                                                :active-picker.sync="activePicker"
                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                min="1940-01-01"
                                                @change="n1save"
                                            ></v-date-picker>
                                        </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pb-0" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Relationship"
                                            v-model="nominee1.n1_relation"
                                            :error-messages="n1_relationErrors"
                                            required
                                            @blur="$v.nominee1.n1_relation.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pb-0" cols=6>
                                
                                    <v-text-field class=""
                                        type="number"
                                            color='#004B55'
                                            dense
                                            suffix="%"
                                            hide-spin-buttons
                                            label="Nominee Percentage"
                                            v-model="nominee1.n1_percent"
                                            :error-messages="n1_percentErrors"
                                            required
                                            @blur="$v.nominee1.n1_percent.$touch()"
                                        ></v-text-field>
                            </v-col>
                            </v-row>

                            <v-row class="mt-1">
                            <v-col cols=12 class="py-0 pt-3">
                                        <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Address"
                                            v-model="nominee1.n1_addr"
                                            :error-messages="n1_addrErrors"
                                            required
                                            @blur="$v.nominee1.n1_addr.$touch()"
                                        ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pt-2">
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="City"
                                            v-model="nominee1.n1_city"
                                            :error-messages="n1_cityErrors"
                                            required
                                            @blur="$v.nominee1.n1_city.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pt-2">

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="State"
                                            v-model="nominee1.n1_state"
                                            :error-messages="n1_stateErrors"
                                            required
                                            @blur="$v.nominee1.n1_state.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pt-1" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Country"
                                            v-model="nominee1.n1_country"
                                            :error-messages="n1_countryErrors"
                                            required
                                            @blur="$v.nominee1.n1_country.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pt-1" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Pincode"
                                            type="number"
                                            hide-spin-buttons
                                            v-model="nominee1.n1_pincode"
                                            :error-messages="n1_pincodeErrors"
                                            required
                                            @blur="$v.nominee1.n1_pincode.$touch()"
                                        ></v-text-field>

                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="py-0 pt-2" cols=6> <!-- :items="Incomeitems" -->
                                    <v-select dense
                                    :items="nominee1.n1_proofTypeList"
                                    color='#004B55'
                                    label="ID Proof Type"
                                    v-model="nominee1.n1_proofType"
                                    :error-messages="n1_proofTypeErrors"
                                    required
                                    @blur="$v.nominee1.n1_proofType.$touch()"
                                    ></v-select>
                                </v-col>
                                <v-col class="py-0 pt-1" cols=6>
                                
                                    <v-file-input  
                                        outlined
                                        truncate-length="15"
                                        dense
                                        prepend-icon=""
                                        color='#004B55'
                                        label="Attach Proof"
                                        small-chips
                                        accept="image/png, image/jpg, image/jpeg,application/pdf"
                                        v-model="nominee1.n1_proof"
                                        :error-messages="n1_proofErrors"
                                        required
                                        @blur="$v.nominee1.n1_proof.$touch()"
                                ></v-file-input>
                                </v-col>
                            </v-row>
                </v-tab-item>  
                    
                <v-tab-item class="py-3">
                            <v-row class="">
                                <v-col cols=12 class="pb-0">
                                    <v-text-field
                                        height="20" 
                                        color='#004B55'
                                        dense
                                        label="Name"
                                        v-model="nominee2.n2_name"
                                        :error-messages="n2_nameErrors"
                                        required
                                        @blur="$v.nominee2.n2_name.$touch()"
                                    ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pb-0 pt-2">
                                        <v-text-field
                                            color='#004B55'
                                            dense
                                            label="Email"
                                            v-model="nominee2.n2_email"
                                            :error-messages="n2_emailErrors"
                                            required
                                            @blur="$v.nominee2.n2_email.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pb-0 pt-2">

                                       <v-text-field 
                                            color='#004B55'
                                            dense
                                            label="Mobile"
                                            type="number"
                                            hide-spin-buttons
                                            prefix="+91"  
                                            v-model="nominee2.n2_mobile"
                                            @keypress="n2phonevalidation($event)"
                                            :error-messages="n2_mobileErrors"
                                            required
                                            @blur="$v.nominee2.n2_mobile.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="">
                                <v-col class="pb-2 pt-2" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="PAN"
                                            @keyup="nominee2.n2_pan ? nominee2.n2_pan=nominee2.n2_pan.toUpperCase():''"
                                            @keypress="n2panNumberValidation($event)"
                                            v-model="nominee2.n2_pan"
                                            :error-messages="n2_panErrors"
                                            required
                                            @blur="$v.nominee2.n2_pan.$touch()"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="pb-2 pt-2" cols=6>

                                        <v-menu
                                        ref="n2_menu"
                                        v-model="n2_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="280px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense
                                                    v-model="nominee2.n2_dob"
                                                    label="DOB"
                                                    color="#004B55"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @change="n2save"
                                                    :error-messages="n2_dobErrors"
                                                    required
                                                    @blur="$v.nominee2.n2_dob.$touch()" 
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="nominee2.n2_dob"
                                            no-title
                                            :active-picker.sync="activePicker"
                                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            min="1940-01-01"
                                            @change="n2save"
                                        ></v-date-picker>
                                        </v-menu>
                                

                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pb-0" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Relationship"
                                            v-model="nominee2.n2_relation"
                                            :error-messages="n2_relationErrors"
                                            required
                                            @blur="$v.nominee2.n2_relation.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pb-0" cols=6>
                                
                                    <v-text-field class=""
                                        type="number"
                                            color='#004B55'
                                            dense
                                            suffix="%"
                                            hide-spin-buttons
                                            label="Nominee Percentage"
                                            v-model="nominee2.n2_percent"
                                            :error-messages="n2_percentErrors"
                                            required
                                            @blur="$v.nominee2.n2_percent.$touch()"
                                        ></v-text-field>
                            </v-col>
                            </v-row>

                            <v-row class="mt-1">
                            <v-col cols=12 class="py-0 pt-3">
                                        <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Address"
                                            v-model="nominee2.n2_addr"
                                            :error-messages="n2_addrErrors"
                                            required
                                            @blur="$v.nominee2.n2_addr.$touch()"
                                        ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pt-2">
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="City"
                                            v-model="nominee2.n2_city"
                                            :error-messages="n2_cityErrors"
                                            required
                                            @blur="$v.nominee2.n2_city.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pt-2">

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="State"
                                            v-model="nominee2.n2_state"
                                            :error-messages="n2_stateErrors"
                                            required
                                            @blur="$v.nominee2.n2_state.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pt-1" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Country"
                                            v-model="nominee2.n2_country"
                                            :error-messages="n2_countryErrors"
                                            required
                                            @blur="$v.nominee2.n2_country.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pt-1" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Pincode"
                                            type="number"
                                            hide-spin-buttons
                                            v-model="nominee2.n2_pincode"
                                            :error-messages="n2_pincodeErrors"
                                            required
                                            @blur="$v.nominee2.n2_pincode.$touch()"
                                        ></v-text-field>

                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="py-0 pt-2" cols=6> <!-- :items="Incomeitems" -->
                                    <v-select dense
                                    :items="nominee2.n2_proofTypeList"
                                    color='#004B55'
                                    label="ID Proof Type"
                                    v-model="nominee2.n2_proofType"
                                    :error-messages="n2_proofTypeErrors"
                                    required
                                    @blur="$v.nominee2.n2_proofType.$touch()"
                                    ></v-select>
                                </v-col>
                                <v-col class="py-0 pt-1" cols=6>
                                
                                    <v-file-input  
                                        outlined
                                        truncate-length="15"
                                        dense
                                        prepend-icon=""
                                        color='#004B55'
                                        label="Attach Proof"
                                        small-chips
                                        accept="image/png, image/jpg, image/jpeg,application/pdf"
                                        v-model="nominee2.n2_proof"
                                        :error-messages="n2_proofErrors"
                                        required
                                        @blur="$v.nominee2.n2_proof.$touch()"
                                ></v-file-input>
                                </v-col>
                            </v-row>
                </v-tab-item> 
                
                <v-tab-item class="py-3">
                            <v-row class="">
                                <v-col cols=12 class="pb-0">
                                    <v-text-field
                                        height="20"
                                        color='#004B55'
                                        dense
                                        label="Name"
                                        v-model="nominee3.n3_name"
                                        :error-messages="n3_nameErrors"
                                        required
                                        @blur="$v.nominee3.n3_name.$touch()"
                                    ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pb-0 pt-2">
                                        <v-text-field
                                            color='#004B55'
                                            dense
                                            label="Email"
                                            v-model="nominee3.n3_email"
                                            :error-messages="n3_emailErrors"
                                            required
                                            @blur="$v.nominee3.n3_email.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pb-0 pt-2">

                                    <v-text-field 
                                            color='#004B55'
                                            dense
                                            label="Mobile"
                                            type="number"
                                            hide-spin-buttons
                                            prefix="+91"  
                                            v-model="nominee3.n3_mobile"
                                            @keypress="n3phonevalidation($event)"
                                            :error-messages="n3_mobileErrors"
                                            required
                                            @blur="$v.nominee3.n3_mobile.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="">
                                <v-col class="pb-2 pt-2" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="PAN"
                                            @keyup="nominee3.n3_pan ? nominee3.n3_pan=nominee3.n3_pan.toUpperCase():''"
                                            @keypress="n3panNumberValidation($event)"
                                            v-model="nominee3.n3_pan"
                                            :error-messages="n3_panErrors"
                                            required
                                            @blur="$v.nominee3.n3_pan.$touch()"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="pb-2 pt-2" cols=6>

                                        <v-menu
                                        ref="n3_menu"
                                        v-model="n3_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="280px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense
                                                    v-model="nominee3.n3_dob"
                                                    label="DOB"
                                                    color="#004B55"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @change="n3save"
                                                    :error-messages="n3_dobErrors"
                                                    required
                                                    @blur="$v.nominee3.n3_dob.$touch()" 
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="nominee3.n3_dob"
                                            no-title
                                            :active-picker.sync="activePicker"
                                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            min="1940-01-01"
                                            @change="n3save"
                                        ></v-date-picker>
                                        </v-menu>
                                

                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pb-0" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Relationship"
                                            v-model="nominee3.n3_relation"
                                            :error-messages="n3_relationErrors"
                                            required
                                            @blur="$v.nominee3.n3_relation.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pb-0" cols=6>
                                
                                    <v-text-field class=""
                                        type="number"
                                            color='#004B55'
                                            dense
                                            suffix="%"
                                            hide-spin-buttons
                                            label="Nominee Percentage"
                                            v-model="nominee3.n3_percent"
                                            :error-messages="n3_percentErrors"
                                            required
                                            @blur="$v.nominee3.n3_percent.$touch()"
                                        ></v-text-field>
                            </v-col>
                            </v-row>

                            <v-row class="mt-1">
                            <v-col cols=12 class="py-0 pt-3">
                                        <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Address"
                                            v-model="nominee3.n3_addr"
                                            :error-messages="n3_addrErrors"
                                            required
                                            @blur="$v.nominee3.n3_addr.$touch()"
                                        ></v-text-field>
                                </v-col>      

                                <v-col cols=6 class="pt-2">
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="City"
                                            v-model="nominee3.n3_city"
                                            :error-messages="n3_cityErrors"
                                            required
                                            @blur="$v.nominee3.n3_city.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col  cols=6 class="pt-2">

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="State"
                                            v-model="nominee3.n3_state"
                                            :error-messages="n3_stateErrors"
                                            required
                                            @blur="$v.nominee3.n3_state.$touch()"
                                        ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="pt-1" cols=6>
                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Country"
                                            v-model="nominee3.n3_country"
                                            :error-messages="n3_countryErrors"
                                            required
                                            @blur="$v.nominee3.n3_country.$touch()"
                                        ></v-text-field>
                                </v-col>
                                <v-col class="pt-1" cols=6>

                                    <v-text-field class=""
                                            color='#004B55'
                                            dense
                                            label="Pincode"
                                            type="number"
                                            hide-spin-buttons
                                            v-model="nominee3.n3_pincode"
                                            :error-messages="n3_pincodeErrors"
                                            required
                                            @blur="$v.nominee3.n3_pincode.$touch()"
                                        ></v-text-field>

                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col class="py-0 pt-2" cols=6> <!-- :items="Incomeitems" -->
                                    <v-select dense
                                    :items="nominee3.n3_proofTypeList"
                                    color='#004B55'
                                    label="ID Proof Type"
                                    v-model="nominee3.n3_proofType"
                                    :error-messages="n3_proofTypeErrors"
                                    required
                                    @blur="$v.nominee3.n3_proofType.$touch()"
                                    ></v-select>
                                </v-col>
                                <v-col class="py-0 pt-1" cols=6>
                                
                                    <v-file-input  
                                        outlined
                                        truncate-length="15"
                                        dense
                                        prepend-icon=""
                                        color='#004B55'
                                        label="Attach Proof"
                                        small-chips
                                        accept="image/png, image/jpg, image/jpeg,application/pdf"
                                        v-model="nominee3.n3_proof"
                                        :error-messages="n3_proofErrors"
                                        required
                                        @blur="$v.nominee3.n3_proof.$touch()"
                                ></v-file-input>
                                </v-col>
                            </v-row>
                </v-tab-item>  
            </v-tabs-items>
          
   </v-col>
        
        <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">     
             <!-- :disabled="$v.$invalid || cardloader" -->
          <v-btn :disabled="$v.nominee1.$invalid || ($v.nominee2.$invalid && activetab=='tab2') || ($v.nominee3.$invalid && activetab=='tab3') || cardloader"
              block
              style="color:white;"
              color="#FFB900"
              @click="nomineeupdation()"
              class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
              >
                CONTINUE
           </v-btn>
           <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
        </div>
        

</v-card>
    </v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api.js';
  import { validationMixin } from 'vuelidate'
  import { required,email,minLength,helpers,between} from 'vuelidate/lib/validators'
  const alpha = helpers.regex('alpha', /[A-Z]{5}[0-9]{4}[A-Z]{1}/)
 const getage=(dateString)=>{
                var today = new Date();
                var birthDate = new Date(dateString);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
                if (age>=18 && age<=80) return true;
                else return false;
          
 }

  export default {
     mixins: [validationMixin],
    validations: {
         
        nominee1:{
          n1_name:{required,minLength: minLength(4)},
          n1_email:{required,email},
          n1_mobile:{required},
          n1_pan:{required,alpha},
          n1_dob:{required,getage},
          n1_relation:{required},
          n1_percent:{required,between:between(1, 100)},
          n1_addr:{required},
          n1_city:{required},
          n1_state:{required},
          n1_country:{required},
          n1_pincode:{required},
          n1_proofType:{required},
          n1_proof:{required},
              },

        nominee2:{
          n2_name:{required,minLength: minLength(4)},
          n2_email:{required,email},
          n2_mobile:{required},
          n2_pan:{required,alpha},
          n2_dob:{required,getage},
          n2_relation:{required},
          n2_percent:{required,between:between(1, 100)},
          n2_addr:{required},
          n2_city:{required},
          n2_state:{required},
          n2_country:{required},
          n2_pincode:{required},
          n2_proofType:{required},
          n2_proof:{required},
          },

        nominee3:{
          n3_name:{required,minLength: minLength(4)},
          n3_email:{required,email},
          n3_mobile:{required},
          n3_pan:{required,alpha},
          n3_dob:{required,getage},
          n3_relation:{required},
          n3_percent:{required,between:between(1, 100)},
          n3_addr:{required},
          n3_city:{required},
          n3_state:{required},
          n3_country:{required},
          n3_pincode:{required},
          n3_proofType:{required},
          n3_proof:{required},
            }
    },
    data(){
      return {
            tab: null,
            activePicker: null,
            // menu: false,
            cardloader:false,
            n1_menu:false,
            n2_menu:false,
            n3_menu:false,
            activetab:'',


        nominee1:{
            n1_name:null,
            n1_email:null,
            n1_mobile:null,
            n1_pan:null,
            n1_dob:null,
            n1_relation:null,
            n1_percent:null,
            n1_addr:null,
            n1_city:null,
            n1_state:null,
            n1_country:null,
            n1_pincode:null,
            n1_proofTypeList:['pan','aadhar','voter id','driving licence','passport'],
            n1_proofType:null,
            n1_proof:null,
        },
        nominee2: {
            n2_name:null,
            n2_email:null,
            n2_mobile:null,
            n2_pan:null,
            n2_dob:null,
            n2_relation:null,
            n2_percent:null,
            n2_addr:null,
            n2_city:null,
            n2_state:null,
            n2_country:null,
            n2_pincode:null,
            n2_proofTypeList:['pan','aadhar','voter id','driving licence','passport'],
            n2_proofType:null,
            n2_proof:null,
        },
        nominee3:{
            n3_name:null,
            n3_email:null,
            n3_mobile:null,
            n3_pan:null,
            n3_dob:null,
            n3_relation:null,
            n3_percent:null,
            n3_addr:null,
            n3_city:null,
            n3_state:null,
            n3_country:null,
            n3_pincode:null,
            n3_proofTypeList:['pan','aadhar','voter id','driving licence','passport'],
            n3_proofType:null,
            n3_proof:null,
        }     
      };
  },
  methods:{
             getAge(dateString) {
                var today = new Date();
                var birthDate = new Date(dateString);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                console.log("his age is ",age,dateString , this.$v.nominee1.n1_dob);
                return age;
            },

             n1save (n1_dob) {
                        this.$refs.n1_menu.save(n1_dob)
                           },
             n1panNumberValidation($event){
                                    if (this.nominee1.n1_pan){
                                        if (this.nominee1.n1_pan.length >=10) {               
                                            $event.preventDefault();                
                                        }
                                    }  
                               },
             n1phonevalidation($event) {
                              
                                var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                              
                                if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) ) {
                                    $event.preventDefault();
                                 }
                                 if(this.nominee1.n1_mobile) {
                                    if (this.nominee1.n1_mobile.length >= 10) {               
                                        $event.preventDefault();                
                                    }
                                  }
                                
                              },
             n2save (n2_dob) {
                            this.$refs.n2_menu.save(n2_dob)
                           },
             n2panNumberValidation($event){
                                    if (this.nominee2.n2_pan){
                                        if (this.nominee2.n2_pan.length >=10) {               
                                            $event.preventDefault();                
                                        }
                                    }
                                   
                               },
             n2phonevalidation($event) {
                              
                                var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                              
                                if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) ) {
                                    $event.preventDefault();
                                 }
                                 if(this.nominee2.n2_mobile) {
                                    if (this.nominee2.n2_mobile.length >= 10) {               
                                        $event.preventDefault();                
                                    }
                                  }

                                
                              },
             n3save (n3_dob) {
                            this.$refs.n3_menu.save(n3_dob)
                           },
             n3panNumberValidation($event){
                                    if (this.nominee3.n3_pan){
                                        if (this.nominee3.n3_pan.length >=10) {               
                                            $event.preventDefault();                
                                        }
                                    }
                                   
                                 },
             n3phonevalidation($event) {
                              
                                var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                              
                                if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) ) {
                                    $event.preventDefault();
                                 }
                                 if(this.nominee3.n3_mobile) {
                                    if (this.nominee3.n3_mobile.length >= 10) {               
                                        $event.preventDefault();                
                                    }
                                  }

                              },

            nomineeupdation(){
                              
                              console.log("nominee1 : ",(this.nominee1),"nominee2 : ",this.nominee2 , "nominee3 : ",this.nominee3)

                              console.log(atob(localStorage.getItem('hash')) , this.n1_name != null , this.n1_email != null)
                              // && this.n1_name != null && this.n1_email != null  && this.n1_mobile != null && this.n1_pan != null && this.n1_dob && this.n1_relation != null && this.n1_percent != null && this.n1_addr != null  && this.n1_city != null && this.n1_state != null && this.n1_country && this.n1_pincode != null && this.n1_proofType != null && this.n1_proof != null
                              if(atob(localStorage.getItem('hash')) && !this.$v.nominee1.$invalid){ 
                                    this.$v.$reset()
                                    this.cardloader=true;
                                    var axiosThis = this
                                    const nomineedata={objects:[axiosThis.nominee1,axiosThis.nominee2,axiosThis.nominee3],"mobile":atob(localStorage.getItem('hash')) }
                                        //  console.log("nomineedata : ",nomineedata)
                                    var config = {
                                            method: 'post',
                                            url:api.api_url+'/signupGetNomineeDetails',
                                            headers: {'Content-Type': 'application/json'},
                                            data:JSON.stringify(nomineedata)
                                            };
                                    axios(config)
                                        .then(function (response) {
                                        console.log(JSON.stringify(response.data));
                                        let formData = new FormData();
                                        // console.log(axiosThis.n1_proof)
                                        console.log("nomineedta ",typeof(nomineedata),(nomineedata))
                                        formData.append('n1proof', axiosThis.nominee1.n1_proof);
                                        formData.append('n2proof', axiosThis.nominee2.n2_proof);
                                        formData.append('n3proof', axiosThis.nominee3.n3_proof);

                                        axios.post(api.api_url+'/signupUploadNomineeDetails/'+atob(localStorage.getItem('hash')),
                                                formData,{
                                                headers: {'Content-Type': 'multipart/form-data'}
                                                }
                                        // axios.post(api.api_url+'/nomineeupdation?mob='+atob(localStorage.getItem('hash')),
                                        //     formData,{
                                        //     headers: {'Content-Type': 'multipart/form-data'}
                                        //     }
                                        ).then(function (response) {
                                        console.log(JSON.stringify(response.data));
                                        //   axiosThis.$router.push({name:'bank'})
                                        axiosThis.cardloader=false;
                                        if(response.data['data'] =='Uploaded'){
                                             axiosThis.$v.$reset()
                                             axiosThis.$router.replace({name:'bank'})
                                        }
                                        }).catch(function (error) {
                                        console.log(error);
                                        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                        axiosThis.cardloader=false; 
                                        });

                                    }).catch(function (error) {
                                    console.log(error);
                                    axiosThis.cardloader=false;
                                    alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                    });
                                  }else {
                                  console.log("fill all field")
                              }
                            },
  },
  computed: {
     
                n1_nameErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_name.$dirty) return errors
                          !this.$v.nominee1.n1_name.required && errors.push('Mandatory Field')
                          !this.$v.nominee1.n1_name.minLength && errors.push('Must be valid')
                          return errors
                           
                           },  
                n1_emailErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_email.$dirty) return errors
                           !this.$v.nominee1.n1_email.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_email.required && errors.push('Mandatory Field')
                          return errors
                           
                           },               

                n1_mobileErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_mobile.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_mobile.required && errors.push('Mandatory Field')
                          return errors
                           
                           },   

                n1_panErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_pan.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_pan.required && errors.push('Mandatory Field')
                          !this.$v.nominee1.n1_pan.alpha && errors.push('Enter a valid PAN')
                          
                          return errors
                           
                           },   

                n1_dobErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_dob.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_dob.required && errors.push('Mandatory Field')
                          !this.$v.nominee1.n1_dob.getage && errors.push('Enter a valid Age')
                          return errors
                           
                           }, 

                n1_relationErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_relation.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_relation.required && errors.push('Mandatory Field')
                          return errors
                           
                           },      
                
                n1_percentErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_percent.$dirty) return errors
                           !this.$v.nominee1.n1_percent.between && errors.push('Enter valid share')
                          !this.$v.nominee1.n1_percent.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n1_addrErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_addr.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_addr.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n1_cityErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_city.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_city.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n1_stateErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_state.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_state.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n1_countryErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_country.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_country.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n1_pincodeErrors(){
                        const errors = []
                        if (!this.$v.nominee1.n1_pincode.$dirty) return errors
                    //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee1.n1_pincode.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n1_proofTypeErrors(){
                        const errors = []
                        if (!this.$v.nominee1.n1_proofType.$dirty) return errors
                    //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee1.n1_proofType.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n1_proofErrors(){
                           const errors = []
                          if (!this.$v.nominee1.n1_proof.$dirty) return errors
                        //    !this.$v.n1_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee1.n1_proof.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  



                n2_nameErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_name.$dirty) return errors
                          !this.$v.nominee2.n2_name.required && errors.push('Mandatory Field')
                          !this.$v.nominee2.n2_name.minLength && errors.push('Must be valid')
                          return errors
                           
                           },  
                n2_emailErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_email.$dirty) return errors
                           !this.$v.nominee2.n2_email.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_email.required && errors.push('Mandatory Field')
                          return errors
                           
                           },               

                n2_mobileErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_mobile.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_mobile.required && errors.push('Mandatory Field')
                          return errors
                           
                           },   

                n2_panErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_pan.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_pan.required && errors.push('Mandatory Field')
                          !this.$v.nominee2.n2_pan.alpha && errors.push('Enter a valid PAN')
                          
                          return errors
                           
                           },   

                n2_dobErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_dob.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_dob.required && errors.push('Mandatory Field')
                          !this.$v.nominee2.n2_dob.getage && errors.push('Enter a valid Age')
                          return errors
                           
                           }, 

                n2_relationErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_relation.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_relation.required && errors.push('Mandatory Field')
                          return errors
                           
                           },      
                
                n2_percentErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_percent.$dirty) return errors
                           !this.$v.nominee2.n2_percent.between && errors.push('Enter valid share')
                          !this.$v.nominee2.n2_percent.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n2_addrErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_addr.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_addr.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n2_cityErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_city.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_city.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n2_stateErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_state.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_state.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n2_countryErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_country.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_country.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n2_pincodeErrors(){
                        const errors = []
                        if (!this.$v.nominee2.n2_pincode.$dirty) return errors
                    //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee2.n2_pincode.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n2_proofTypeErrors(){
                        const errors = []
                        if (!this.$v.nominee2.n2_proofType.$dirty) return errors
                    //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee2.n2_proofType.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n2_proofErrors(){
                           const errors = []
                          if (!this.$v.nominee2.n2_proof.$dirty) return errors
                        //    !this.$v.n2_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee2.n2_proof.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  



                n3_nameErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_name.$dirty) return errors
                          !this.$v.nominee3.n3_name.required && errors.push('Mandatory Field')
                          !this.$v.nominee3.n3_name.minLength && errors.push('Must be valid')
                          return errors
                           
                           },  
                n3_emailErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_email.$dirty) return errors
                           !this.$v.nominee3.n3_email.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_email.required && errors.push('Mandatory Field')
                          return errors
                           
                           },               

                n3_mobileErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_mobile.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_mobile.required && errors.push('Mandatory Field')
                          return errors
                           
                           },   

                n3_panErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_pan.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_pan.required && errors.push('Mandatory Field')
                          !this.$v.nominee3.n3_pan.alpha && errors.push('Enter a valid PAN')
                          
                          return errors
                           
                           },   

                n3_dobErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_dob.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_dob.required && errors.push('Mandatory Field')
                          !this.$v.nominee3.n3_dob.getage && errors.push('Enter a valid Age')
                          return errors
                           
                           }, 

                n3_relationErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_relation.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_relation.required && errors.push('Mandatory Field')
                          return errors
                           
                           },      
                
                n3_percentErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_percent.$dirty) return errors
                           !this.$v.nominee3.n3_percent.between && errors.push('Enter valid share')
                          !this.$v.nominee3.n3_percent.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n3_addrErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_addr.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_addr.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n3_cityErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_city.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_city.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n3_stateErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_state.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_state.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n3_countryErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_country.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_country.required && errors.push('Mandatory Field')
                          return errors
                           
                           },  

                n3_pincodeErrors(){
                        const errors = []
                        if (!this.$v.nominee3.n3_pincode.$dirty) return errors
                    //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee3.n3_pincode.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n3_proofTypeErrors(){
                        const errors = []
                        if (!this.$v.nominee3.n3_proofType.$dirty) return errors
                    //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                        !this.$v.nominee3.n3_proofType.required && errors.push('Mandatory Field')
                        return errors
                        
                           },  

                n3_proofErrors(){
                           const errors = []
                          if (!this.$v.nominee3.n3_proof.$dirty) return errors
                        //    !this.$v.n3_mobile.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee3.n3_proof.required && errors.push('Mandatory Field')
                          return errors  
                           },  
          },

   watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },

    mounted(){
        var axiosThis = this 
        var json_data={
          "mobile":atob(localStorage.getItem('hash')),
             }
          var config = {
            method: 'post',
            url:api.api_url+'/signupGetNomineeDetails',
            headers: {'Content-Type': 'application/json'},
            data:JSON.stringify(json_data)
          };

        axios(config)
        .then(function (response) {
          //  console.log("mounted  == ", response.data);
                var data = response.data
                if(data["nominee1"] ){
                // axiosThis.$router.push({name:'bank'})
                    axiosThis.cardloader=false;
                    axiosThis.nominee1.n1_name=data["nominee1"]["Nom1_name"]
                    axiosThis.nominee1.n1_email=data["nominee1"]["Nom1_email"]
                    axiosThis.nominee1.n1_mobile=data["nominee1"]["Nom1_mobile"]
                    axiosThis.nominee1.n1_pan=data["nominee1"]["Nom1_PAN"]
                    axiosThis.nominee1.n1_dob=data["nominee1"]["Nom1_DOB"]
                    axiosThis.nominee1.n1_relation=data["nominee1"]["Nom1_relation"]
                    axiosThis.nominee1.n1_percent=data["nominee1"]["Nom1_percent_feild1"]
                    axiosThis.nominee1.n1_addr=data["nominee1"]["Nom1_address1"]
                    axiosThis.nominee1.n1_city=data["nominee1"]["Nom1_city"]
                    axiosThis.nominee1.n1_state=data["nominee1"]["Nom1_state"]
                    axiosThis.nominee1.n1_country=data["nominee1"]["Nom1_country"]
                    axiosThis.nominee1.n1_pincode=data["nominee1"]["Nom1_pincode"]
                    axiosThis.nominee1.n1_proofType=data["nominee1"]["nom1_doc_type"]
                }
                if(data["nominee2"] ){
                // axiosThis.$router.push({name:'bank'})
                    axiosThis.cardloader=false;
                    axiosThis.nominee2.n2_name=data["nominee2"]["Nom2_name"]
                    axiosThis.nominee2.n2_email=data["nominee2"]["Nom2_email"]
                    axiosThis.nominee2.n2_mobile=data["nominee2"]["Nom2_mobile"]
                    axiosThis.nominee2.n2_pan=data["nominee2"]["Nom2_PAN"]
                    axiosThis.nominee2.n2_dob=data["nominee2"]["Nom2_DOB"]
                    axiosThis.nominee2.n2_relation=data["nominee2"]["Nom2_relation"]
                    axiosThis.nominee2.n2_percent=data["nominee2"]["Nom2_percent_feild1"]
                    axiosThis.nominee2.n2_addr=data["nominee2"]["Nom2_address1"]
                    axiosThis.nominee2.n2_city=data["nominee2"]["Nom2_city"]
                    axiosThis.nominee2.n2_state=data["nominee2"]["Nom2_state"]
                    axiosThis.nominee2.n2_country=data["nominee2"]["Nom2_country"]
                    axiosThis.nominee2.n2_pincode=data["nominee2"]["Nom2_pincode"]
                    axiosThis.nominee2.n2_proofType=data["nominee2"]["nom2_doc_type"]
                }
                if(data["nominee3"] ){
                // axiosThis.$router.push({name:'bank'})
                    axiosThis.cardloader=false;
                    axiosThis.nominee3.n3_name=data["nominee3"]["Nom3_name"]
                    axiosThis.nominee3.n3_email=data["nominee3"]["Nom3_email"]
                    axiosThis.nominee3.n3_mobile=data["nominee3"]["Nom3_mobile"]
                    axiosThis.nominee3.n3_pan=data["nominee3"]["Nom3_PAN"]
                    axiosThis.nominee3.n3_dob=data["nominee3"]["Nom3_DOB"]
                    axiosThis.nominee3.n3_relation=data["nominee3"]["Nom3_relation"]
                    axiosThis.nominee3.n3_percent=data["nominee3"]["Nom3_percent_feild1"]
                    axiosThis.nominee3.n3_addr=data["nominee3"]["Nom3_address1"]
                    axiosThis.nominee3.n3_city=data["nominee3"]["Nom3_city"]
                    axiosThis.nominee3.n3_state=data["nominee3"]["Nom3_state"]
                    axiosThis.nominee3.n3_country=data["nominee3"]["Nom3_country"]
                    axiosThis.nominee3.n3_pincode=data["nominee3"]["Nom3_pincode"]
                    axiosThis.nominee3.n3_proofType=data["nominee3"]["nom3_doc_type"]
                }
            })
            .catch(function (error) { 
            console.log(error);
            alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
            });

    },
}
</script>

<style>
.v-label {
    font-size:14px !important;
}


.v-slide-group__wrapper {
    display: inline-block !important;
}

</style>