<template>
  <v-container class="pa-0" fluid fill-height style="background-color: #f0f5fa">
    <v-card
      tile
      height="100%"
      max-width="450"
      elevation="0"
      class="px-9 px-lg-12 mx-auto">
      <v-progress-linear absolute color='#004B55' height=10 value="58"></v-progress-linear>
      <v-col  v-if="!Otp_screen && !kycLoader" class="pt-6 px-0 pb-0">
         <v-img
          :src="require('@/assets/invezt.svg')"
          class="ml-n4"
          contain
          width="110"
        />

        <h2
          class="mt-1"
          style="color: #004b55; font-size:16px"
        >
          Connect Digilocker
        </h2>
          <p
          class="mt-1"
          style="font-size: 14px; font-weight:500"
        >
          Share Aadhar details from Digilocker
        </p>

       <h2
          class="mt-16 pt-6"
          style="
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
          ">
          I agree that I have read the Aadhar user consent
          document and voluntarily consent to share my Aadhar information with Invezt /
          Zebu for conducting KYC for opening a trading and Demat account
          adhering to KRA regulations (2011) and PMLA guidelines (2002).
        </h2>

    <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
        <v-btn :disabled="cardloader"
          v-if="!Otp_screen"
          block
          style="color: white"
          color="#FFB900"
          @click="connectDigio()"
          class="mt-8 pl-0 pe-12 pe-md-3 font-weight-bold"
          >YES, I AGREE</v-btn>
      <v-progress-linear v-if="!Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
     </div>

      </v-col>
    
        <v-col v-if="kycLoader"  class="pt-6 px-0 pb-0">
        <v-img
          :src="require('@/assets/invezt.svg')"
          class="ml-n4"
          contain
          width="110"
        />
        <div class=" pt-lg-16 mt-lg-16 justify-content-center text-center"> </div>
        <h3>fetching Data from Digilocker</h3>
      
          <v-progress-linear
          class="mt-3 pt-3"
            color="#FFB900"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
         
        </v-col>
   


      <v-col  v-if="Otp_screen" class="pt-6 px-0 pb-0">
        <v-img
          :src="require('@/assets/invezt.svg')"
          class="ml-n4"
          contain
          width="110"
        />
        <h2
          class="mt-1"
          style="color: #004b55; font-size: 16px"
        >
          Aadhar Data
        </h2>
       
        <p
          class="mt-1"
          style="font-size: 14px; font-weight: 500"
        >
          Fetched from your Aadhar records
        </p>
       

        <v-row v-if="Otp_screen" class="mt-6 px-3">
          <v-avatar size="50" style="background-color: #004b55">
            <span class="white--text text-h5">{{ name[0] }}</span>
          </v-avatar>
          <v-col class="pa-0 px-5">
            <h2 style="font-size: 16px; color: #004b55">{{ name }}</h2>
            <p>{{ aadharNo }}</p>
          </v-col>
        </v-row>
        <v-row v-if="Otp_screen" class="pa-0 mt-5">
          <v-img
            :src="require('@/assets/calendar.svg')"
            contain
            color='#004B55'
            class="mt-4"
            height="23"
            max-width="24"
          />
          <v-col class="pa-3" cols="6">
              <v-text-field
                  v-model="dob"
                  class="pa-0 ma-0"
                  label="DOB"
                  color='#004B55'
                  readonly
                  disabled
              ></v-text-field>
          </v-col>
          <v-img
            :src="require('@/assets/gend.svg')"
            contain
            class="mt-4"
            height="23"
            max-width="24"
          />
          <v-col class="pa-3 cols=6">
             <v-text-field v-model="gender" disabled color='#004B55' dense label="Gender"></v-text-field>   <!--   :hide-details="true" -->
            <!-- <v-text-field dense label=""></v-text-field> -->
          </v-col>
        </v-row>
        <v-row v-if="Otp_screen">
          <v-img
            :src="require('@/assets/father.svg')"
            contain
            class="mt-2 ml-3"
            height="20"
            max-width="24"
          />
          <v-col class="pa-0 px-2">
            <v-text-field v-model="fatherName"  disabled color='#004B55' dense label="Father/Spouse Name"></v-text-field>
          </v-col>
        </v-row>
        <p
         
          class="mt-3"
          style="font-size: 14px; font-weight: 500"
        >
          Your Address
        </p>
        <v-card  outlined class="pb-6 px-6 pt-3">
          <p class="ma-0" style="font-size: 14px">  <!-- text-align: center; -->
            {{ address }}
          </p>
        </v-card>
        <p
          class="mt-5"
          style="font-size: 12px; font-weight: 500; text-align: center">
          This address will be used as your communication address by Invezt /
          Zebu</p> <!-- , If want to change<a style="text-decoration: none" href="#">Click Here</a> -->

       <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;"> 
        <router-link
                        :to="'/personal'"
                        class="text-decoration-none"
                    >
        <v-btn :disabled='cardloader'
          block
          style="color: white"
          color="#FFB900"
          class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
          >CONTINUE</v-btn>
           <v-progress-linear v-if="Otp_screen" color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
        </router-link>
        </div>

      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import api from '@/api';
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      Otp_screen: false,
      name: null,
      dob :null,
      aadharNo: null,
      address:null,
      country: null,
      district: null,
      fatherName: null,
      pinCode: null,
      state: null,
      genders:null,
      cardloader:false,
      kycLoader:false,
  }),
  methods: {
    open() {
      this.Otp_screen =true ; //!this.Otp_screen
      this.cardloader=true;
      this.$router.replace({name:'pan'})
    },
    connectDigio(){
      this.cardloader=true
      var stateid = "signup" + Math.random().toString(36).substring(7);
      var config = {
        method: 'post',
        url: api.api_url+'/digiLocker-transactionid?mob='+atob(localStorage.getItem('hash'))+'&id='+stateid,
        headers: { }
      };
      var axiosThis = this 
      axios(config)
      .then(function () {
        // console.log(response.data)
        window.location.href ="https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=96EA800D&state=" +stateid +"";
        axiosThis.cardloader=false;
      })
      .catch(function (error) { 
        console.log(error);
        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
      });
      
    },
  },
  mounted(){
        // console.log("Mounted Hi");
        var dataFromDigi = this.$route.query.code;
        var stateid = this.$route.query.state;
        var axiosThis =this
        this.cardloader=false;
        if (dataFromDigi)
        {
          this.kycLoader=true

          this.mobile_num = atob(localStorage.getItem('hash'))
          this.step=3;
          var config = {
            method: 'post',
            url: api.api_url+'/digiLockerFetch?mob='+this.mobile_num+'&code='+dataFromDigi+'&state='+stateid,
            headers: { }
          };
          axios(config)
          .then(function (response) {

            if ("error" in response.data){
              axiosThis.kycLoader=false
              alert("Unable to fetch your details, please try again. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
              axiosThis.$router.replace({name:'Aadhar'})
            }else{
            axiosThis.Otp_screen =true
            axiosThis.kycLoader=false
            axiosThis.KycFetchDisp=true
            axiosThis.name = response.data['name']
            axiosThis.dob = response.data['DoB']
            axiosThis.aadharNo = response.data['aadharNo']
            axiosThis.address = response.data['address']
            axiosThis.country = response.data['country']
            axiosThis.district = response.data['district']
            axiosThis.fatherName = response.data['fatherName']
            axiosThis.pinCode = response.data['pincode']
            axiosThis.state =  response.data['states']
            axiosThis.gender = response.data['gender']
            }
          })
          .catch(function (error) {
            console.log(error);
            var configCheck = {
              method: 'post',
              url: api.api_url+'/digiLockerFetch?mob='+atob(localStorage.getItem('hash')),
              headers: { }
            };
            
            axios(configCheck)
            .then(function (response) {
              if(response.data['aadharNo']){
                    // console.log(response.data)
                    // axiosThis.kycLoader=false
                    axiosThis.KycFetchDisp=true
                    axiosThis.name = response.data['name']
                    axiosThis.dob = response.data['DoB']
                    axiosThis.aadharNo = response.data['aadharNo']
                    axiosThis.address = response.data['address']
                    axiosThis.country = response.data['country']
                    axiosThis.district = response.data['district']
                    axiosThis.fatherName = response.data['fatherName']
                    axiosThis.pinCode = response.data['pincode']
                    axiosThis.state =  response.data['states']
                    axiosThis.gender = response.data['gender']
                    axiosThis.Otp_screen =true
                }
                  })
                  .catch(function (error) {
                    console.log(error);
                    axiosThis.kycLoader=true;
                    alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                  });
                });
              }
              else{
                  var configCheck = {
                    method: 'post',
                    url: api.api_url+'/digiLockerFetch?mob='+atob(localStorage.getItem('hash')),
                    headers: { }
                  };
                  
                  axios(configCheck)
                  .then(function (response) {
                    if(response.data['aadharNo']){
                      // console.log(response.data)
                      // axiosThis.kycLoader=false
                      axiosThis.KycFetchDisp=true
                      axiosThis.name = response.data['name']
                      axiosThis.dob = response.data['DoB']
                      axiosThis.aadharNo = response.data['aadharNo']
                      axiosThis.address = response.data['address']
                      axiosThis.country = response.data['country']
                      axiosThis.district = response.data['district']
                      axiosThis.fatherName = response.data['fatherName']
                      axiosThis.pinCode = response.data['pincode']
                      axiosThis.state =  response.data['states']
                      axiosThis.gender = response.data['gender']
                      axiosThis.Otp_screen =true
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                    alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                    // axiosThis.kycLoader=true;
                  });
        }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
* {
  font-family: "Inter", sans-serif;
}
</style>