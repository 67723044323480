<template>
    <v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">
<v-card tile height="100%" width="450"  elevation="0" class="px-9 px-lg-12 mx-auto">
   <v-progress-linear absolute color='#004B55' height=10 value="72"></v-progress-linear>
  <v-col  class="pt-6 px-0 pb-0">
              <v-img 
                :src="require('@/assets/invezt.svg')"
                contain
                class="ml-n4 py-2 py-lg-0"
                width="110" 
                />
                <h2  class="mt-1" style="color:#004B55;font-size:16px;">Help Us Know You</h2>
                <p  class="mt-1" style="font-size:14px;font-weight:500" >Enter your Personal Details</p>

                <v-text-field class="mt-6"
                  color='#004B55'
                  v-model="motherName"
                  dense
                  label="Mother Name"
                  :error-messages="motherErrors"
                  required
                
                  @blur="$v.motherName.$touch()"
                ></v-text-field>

                <v-row class="pa-0">
                  <v-col cols=6>
                        <v-select
                            color='#004B55'
                            :items="Educationitems"
                            label="Education"
                            :error-messages="qualificationErrors"
                            v-model="qualification"
                            required
                          
                            @blur="$v.qualification.$touch()"
                        ></v-select>
                  </v-col>
                  <v-col  cols=6>

                      <v-select
                        color='#004B55'
                        :items="Occupationitems"
                        label="Occupation"
                        v-model="occupation"
                      :error-messages="occupationErrors"
                        required
                       
                        @blur="$v.occupation.$touch()"
                      ></v-select>
                  </v-col>
                </v-row>
            <v-row class="my-0">
            <v-col class="pa-0 px-3" cols=6>
                <v-select
                    color='#004B55'
                  :items="Maritalitems"
                  label="Marital Status"
                  v-model="maritialStatus"
                  :error-messages="maritialErrors"
                  required
                 
                  @blur="$v.maritialStatus.$touch()"
                ></v-select>
            </v-col>
            <v-col class="pa-0 px-3" cols=6>

                <v-select
                  color='#004B55'
                  :items="Incomeitems"
                  label="Annual Income"
                  :error-messages="IncomeErrors"
                  v-model="annualIncome"
                  required
                
                  @blur="$v.annualIncome.$touch()"
                ></v-select>

          </v-col>
          </v-row>
          <v-row class="my-0">
                <v-col class="pa-0 px-3" cols=6>

                <v-text-field
                  hide-spin-buttons
                  color='#004B55'
                  type='number'
                  label="Trading Experience"
                  v-model="tradingExperience"
                  :error-messages="texpErrors"
                 
                ></v-text-field>
                </v-col>
                <v-col class="pa-0 px-3" cols=6>
             
              <v-select
                  color='#004B55'
                  :items="Politicallyitems"
                  label="Politically Exposed"
                  @change="checkingforpolitical()"
                  v-model="politicalExposed"
              ></v-select>
          </v-col>
          </v-row>

          <v-row class="align-center pt-0 pb-4 mt-0 pl-2" >
                <v-col cols=6 style="font-size:13px;" class="pl-2 px-0">
                     <span> Do you want to add a nominee</span> 
                </v-col >

                  <v-col  class="px-0">
                      <v-radio-group
                          row
                          v-model="nominee"
                          hide-details
                          class="mt-0"
                          :error-messages="nomineeErrors"
                           dense
                           @input="$v.nominee.$touch()"
                           @blur="$v.nominee.$touch()"
                           required
                        >
                          <v-radio
                            label="Yes"
                            value="yes"
                            color='#004B55'
                          
                          ></v-radio>
                          <v-radio
                            label="No"
                            value="no"
                            color='#004B55'
                          
                          ></v-radio>
                        </v-radio-group>
                    </v-col>
                  
            </v-row>

         <v-snackbar class="mb-16" v-model="snackbar" absolute color="#002d33">
            <b>{{ toasttext }}</b>
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="#FFB900"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                  >
                  Close
                </v-btn>
            </template>
         </v-snackbar>
        </v-col>
       <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
          <v-btn :disabled=" $v.$invalid || cardloader || checkpolitical "   
              block
              style="color: white"
              color="#FFB900"
              @click="personalupdation()"
              class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
              >
              CONTINUE
           </v-btn>
           <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
        </div>

</v-card>
    </v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api';
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  export default {
     mixins: [validationMixin],
          validations: {
         
          qualification:{required},
          occupation:{required},
          motherName:{required},
          maritialStatus:{required},
          annualIncome:{required},
          nominee:{required},
          },


    data(){
      return {
            Maritalitems:['Single','Married'],
            Incomeitems:['below 1 lac','1-5 lac','5-10 lac','10-25 lac','25-1Cr','>1Cr lac'],
            Politicallyitems:['No','Yes'],
            Educationitems:['Highschool' , 'Graduation' , 'Post Graduation' , 'Doctrate' , 'Prof. Degree' , 'Under High School' , 'Illiterate-Other'] ,
            Occupationitems:['Private Sector','Public Sector','Government Service','Business','Student','Retired','Housewife','Professional Agriculturist','Forex Dealer','Other'],
            motherName:null,
            tradingExperience:0,
            qualification:null,
            occupation:null,
            maritialStatus:null,
            annualIncome:'10-25 lac',
            politicalExposed:'No',
            nominee:null,
            snackbar:false,
            toasttext:'',
            cardloader:false,
            checkpolitical:false,
      };
  },
  methods:{

          checkingforpolitical(){
              if(this.politicalExposed=='Yes'){
                this.snackbar=true
                  this.toasttext="As per SEBI norms Politically Exposed Persons (PEP) are not allowed to proceed with online eKYC, Please contact support"
                  this.checkpolitical=true

              }else{
                 this.checkpolitical=false
              }

          },
          personalupdation(){
                              this.$v.$touch() 
                              // console.log(atob(localStorage.getItem('hash')) , this.qualification != null , this.occupation != null)
                              if(atob(localStorage.getItem('hash')) && this.qualification != null && this.occupation != null  && this.motherName != null && this.maritialStatus != null && this.annualIncome && this.politicalExposed == 'No' && this.nominee != null){ 
                                this.cardloader=true;
                                this.$v.$reset()
                                // var config = {
                                //   method: 'post',
                                //   url: api.api_url+'/personalupdation?mob='+atob(localStorage.getItem('hash'))+'&tradeExp='+this.tradingExperience+'&qualification='+this.qualification+'&occupation='+this.occupation+'&motherName='+this.motherName+'&maritialStatus='+this.maritialStatus+'&annualIncome='+this.annualIncome+'&politicalExposed='+this.politicalExposed+'&nominee='+this.nominee,
                                //   headers: { }
                                // };
                                var axiosThis = this
                                  var json_data={
                                      mobile:atob(localStorage.getItem('hash')),
                                      // segment: segment,
                                      //  spousename: axiosThis. spousename,
                                      // fathername: father_name,
                                      maritalstatus:axiosThis.maritialStatus,
                                      annual_income:axiosThis.annualIncome,
                                      politically_exposed:axiosThis.politicalExposed,
                                      mothername:axiosThis.motherName,
                                      Education:axiosThis.qualification,
                                      TradingExperience:axiosThis.tradingExperience,
                                      Occupation:axiosThis.occupation,
                                      nominee_check:axiosThis.nominee,
                                    };
                                var config = {
                                  method: 'post',
                                  url:api.api_url+'/signupGetUserPersonalDetails',
                                  headers: {'Content-Type': 'application/json'},
                                  data:JSON.stringify(json_data)
                                };
                                axios(config)
                                .then(function () {
                                // console.log(JSON.stringify(response.data));
                                if (axiosThis.nominee=='yes'){
                                  axiosThis.$router.push({name:'nominee'})
                                  axiosThis.cardloader=false;
                                
                                }else {
                                  axiosThis.$router.push({name:'bank'})
                                  axiosThis.cardloader=false;
                                }
                                })
                                .catch(function (error) {
                                  console.log(error);
                                  axiosThis.cardloader=false;
                                  alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                });
                              }
                            },

  },
  computed: {
        motherErrors () {
                            const errors = []
                            if (!this.$v.motherName.$dirty) return errors
                            // !this.$v.motherName.maxLength && errors.push('Name must be at most 10 characters long')
                            !this.$v.motherName.required && errors.push('Mandatory Field')
                            return errors
                      },
        qualificationErrors () {
                            const errors = []
                            if (!this.$v.qualification.$dirty) return errors
                            // !this.$v.motherName.maxLength && errors.push('Name must be at most 10 characters long')
                            !this.$v.qualification.required && errors.push('Mandatory Field')
                            return errors
                      
                        },

         occupationErrors () {
                            const errors = []
                            if (!this.$v.occupation.$dirty) return errors
                            !this.$v.occupation.required && errors.push('Mandatory Field')
                            return errors
                             },

          maritialErrors () {
                              const errors = []
                              if (!this.$v.maritialStatus.$dirty) return errors
                              !this.$v.maritialStatus.required && errors.push('Mandatory Field')
                              return errors
                            },

          IncomeErrors () {
                            const errors = []
                            if (!this.$v.annualIncome.$dirty) return errors
                            //!this.$v.annualIncome.maxLength && errors.push('Name must be at most 10 characters long')
                            !this.$v.annualIncome.required && errors.push('Mandatory Field')
                            return errors
                          },


            texpErrors() {
                         // console.log("Trading Exp :",this.tradingExperience)
                            const errors = []
                            if (this.tradingExperience)
                            {
                                if (this.tradingExperience >= 51)
                                {
                                  errors.push('Max span is 50 years')
                                  return errors
                                }
                                return errors
                            }
                            else { 
                                // if (!this.$v.tradingExperience.$dirty) return errors
                                // !this.$v.tradingExperience.required && errors.push('Mandatory Field')
                                return errors
                                }
                          },

               nomineeErrors(){
                           const errors = []
                          if (!this.$v.nominee.$dirty) return errors
                          // !this.$v.email.email && errors.push('Must be valid e-mail')
                          !this.$v.nominee.required && errors.push('Mandatory Field')
                          return errors
                           
                           },         


  },
  mounted(){
    var axiosThis = this 
    var json_data={
      "mobile":atob(localStorage.getItem('hash')),  
      }
      var config = {
        method: 'post',
        url:api.api_url+'/signupGetUserPersonalDetails',
        headers: {'Content-Type': 'application/json'},
        data:JSON.stringify(json_data)
      };
    axios(config)
    .then(function (response) {
      //console.log("Main response == ", (response.data));
        var data = response.data
        if(data['nominee_check']){
          axiosThis.cardloader=false;
          axiosThis.motherName = data['motherName']
          axiosThis.qualification = data['qualification']
          axiosThis.occupation =data['occupation']
          axiosThis.maritialStatus = data['martial_status']
          axiosThis.politicalExposed = data['political_exposed']
          axiosThis.annualIncome = data['annual_income']
          axiosThis.nominee = data['nominee_check']
          axiosThis.tradingExperience = data['tradeExpYear']
        }
    })
    .catch(function (error) { 
      console.log(error);
      alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
    });
  }

}
</script>

