<template>
<v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">
<v-card tile height="100%" width="450" elevation="0" class="px-4 px-lg-12 mx-auto ">
   <v-progress-linear   absolute color='#004B55' height=10 value="44"></v-progress-linear>
  <v-col class="pt-6 px-0 pb-16" >
            <v-img 
              :src="require('@/assets/invezt.svg')"
              contain
              class="ml-n4 py-2 py-lg-0"
              width="110" 
             />
      <div v-if="!paymentSuccessPage">
          <v-row no-gutters>
          <v-col cols=8>
          <h2  class="" style="color:#004B55;font-size:16px;">Pricing</h2>
          </v-col>
         
          </v-row>
          <p  class="mt-1" style="font-size:14px;font-weight:500" >Choose wisely</p>

          <v-item-group mandatory>
          <v-row>
    <!-- Toggle annually -->
            <v-col   class="">      <!--v-if='!switchh'-->
                    <!-- <v-item class="py-4 px-2 " v-slot="{ active, toggle }">
                      <v-card  @click="toggle" :color="active ? 'secondary white--text' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;" >
                        <v-col  @click="selectedapi='₹500',selectedamc='₹500',selectedapx='₹1000/mo',planSelected='Startup'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;" >Startup</h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                            <h1 style="float:right;font-size:18px;"><span>&#x20B9;</span> 0</h1>
                          </v-col>
                        </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600" >For stock market learners</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:400;float:right;" >Per month billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 py-2 px-5">
                            <h6><strong>&#x20B9; 10 /per Order</strong><span class="ml-14 ml-lg-5 " style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->

                    <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;" >
                        <v-col  @click="selectedamc='FREE',selectedapi='FREE',selectedapx='FREE',planSelected='Startups'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Startup </h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                            <h1 style="float:right;font-size:18px;"><span>&#x20B9;</span> 499</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:600" >For Stock Market Learners</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:400;float:right;" >Maintenance charge annually</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5 py-2" >
                            <h6> <strong>&#x20B9; 10 / Order</strong><span class="ml-15 ml-lg-7" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item>

                    <!-- <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle" :color="active ? 'secondary white--text ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedamc='FREE',selectedapi='FREE',selectedapx='FREE',planSelected='Standard'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Standard</h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 5999</h1>
                          </v-col>
                        </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For Low frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:400;float:right;" > Per year billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9; 5 / Order</strong><span class="ml-16 ml-lg-7" style="font-size:10px;font-weight:300;">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->

                    <!-- <v-item v-if='!switchh' class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text  ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedapx='FREE',selectedamc='FREE',selectedapi='FREE',planSelected='Pro'" class="pa-0">
                        <v-row>
                          <v-col cols=3 class="pl-5 pe-0 pa-0 pt-2">
                            <h2  style="font-size:17px;"  >Pro</h2>
                          </v-col>

                           <v-col cols=4>
                                  <span  v-if="!switchh" class="caption input__label pr-2"  style="float:left;">Annually</span>
                                  <span  v-if="switchh" class="caption input__label pr-2"  style="float:left;">Monthly</span>
                                <span>
                                  <v-switch
                                      inset dense
                                      :hide-details="true"
                                      class="pa-0 align-end"
                                      color="secondary"
                                      style="margin:0px"
                                      @click="switch_screen()">
                                  </v-switch>
                                </span>
                            </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 11999</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For High frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2  style="font-size:10px;font-weight:400;float:right;" >Per year billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9;  1 / Order</strong><span class="ml-14 ml-lg-5" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div v-if="active">
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->
                          
                    <!-- <v-item v-if='switchh' class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text  ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedapx='FREE',selectedamc='FREE',selectedapi='FREE',planSelected='Pro'" class="pa-0">
                        <v-row>
                          <v-col cols=3 class="pl-5  pe-0 pa-0 pt-2">
                            <h2  style="font-size:17px;">Pro </h2>
                          </v-col>


                             <v-col cols=4>
                                  <span  v-if="!switchh" class="caption input__label pr-2"  style="float:left;">Annually</span>
                                  <span  v-if="switchh" class="caption input__label pr-2"  style="float:left;">Monthly</span>
                                  <span> <v-switch 
                                      inset dense
                                      :hide-details="true"
                                      class="pa-0 align-start"
                                      color="#FFB900"
                                      style="margin:0px"
                                      @click="switch_screen()"
                                    >
                                  </v-switch></span>
                            </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 1399</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For High frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2  style="font-size:10px;font-weight:400;float:right;" >Per month billed monthly</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9;  1 / Order</strong><span class="ml-14 ml-lg-5" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div v-if="active">
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->


            </v-col>

    <!-- Toggle monthly -->
            <!-- <v-col v-if='switchh'> -->
                    <!-- <v-item class="py-4 px-2 " v-slot="{ active, toggle }">
                      <v-card  @click="toggle" :color="active ? 'secondary white--text' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;" >
                        <v-col  @click="selectedapi='₹500',selectedamc='₹500',selectedapx='₹1000/mo',planSelected='Startup'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;" >Startup</h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                            <h1 style="float:right;font-size:18px;"><span>&#x20B9;</span> 0</h1>
                          </v-col>
                        </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600" >For stock market learners</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2  style="font-size:10px;font-weight:400;float:right;" >Per month billed monthly</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 py-2 px-5">
                            <h6><strong>&#x20B9; 10 /per Order</strong><span class="ml-14 ml-lg-5 " style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->

                    <!-- <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;" >
                        <v-col  @click="selectedamc='₹500',selectedapi='FREE',selectedapx='₹1000/mo',planSelected='Standard'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Startup </h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                            <h1   style="float:right;font-size:18px;"><span>&#x20B9;</span> 349</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:600" >For Stock Market Learners</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                            <h2 style="font-size:10px;font-weight:400;float:right;" >Per month billed monthly</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5 py-2" >
                            <h6> <strong>&#x20B9; 7 /per Order</strong><span class="ml-15 ml-lg-7" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->

                    <!-- <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle" :color="active ? 'secondary white--text ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedamc='FREE',selectedapi='FREE',selectedapx='₹1000/mo',planSelected='Pro'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Standard </h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 699</h1>
                          </v-col>
                        </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For Low frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                            <h2 style="font-size:10px;font-weight:400;float:right;" >Per month billed monthly</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9; 5 /per Order</strong><span class="ml-16 ml-lg-7" style="font-size:10px;font-weight:300;">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item> -->

            <!-- </v-col> -->


          </v-row>
          </v-item-group>

          <!-- <v-row class="ma-0 mt-1">
              <v-img 
          :src="require('@/assets/brok.svg')"
          contain
          max-width="55"
          height="50"
          />
          <p class="py-4 py-lg-4 px-0 px-lg-1 ma-0" style="font-size:16px;text-align:justify;"><span style="font-size:18px;color:#FFB900;font-weight:500">Brokerage</span> on Equity Delivery</p>
          </v-row> -->
          <v-row no-gutters class="mt-5"> 
          <v-col style="border-left:2px solid #004B55;height:30px;" class="px-1 py-0" cols=4>
              <p class="ma-0 " style="font-size:10px;">Acc opening</p>
              <p v-if="selectedapi=='FREE'"  style="color:#FFB900;font-size:11px;font-weight:600">{{selectedapi}}</p>
              <p v-if="selectedapi!='FREE'"  style="color:#004B55;font-size:11px;font-weight:600">{{selectedapi}}</p>
            
            </v-col>
            <!-- <v-col style="border-left:2px solid #004B55;height:30px;" class="px-1 py-0" cols=3>
              <p class="ma-0 " style="font-size:10px;">AMC</p>
              <p v-if="selectedamc=='FREE'" style="color:#FFB900;font-size:11px;font-weight:600">{{selectedamc}}</p>
              <p v-if="selectedamc!='FREE'" style="color:#004B55;font-size:11px;font-weight:600">{{selectedamc}}</p>
            </v-col> -->
            <v-col style="border-left:2px solid #004B55;height:30px;" class="px-1 py-0" cols=4>
              <p class="ma-0 " style="font-size:10px;">API</p>
              <p v-if="selectedapx=='FREE'" style="font-size:10px;"><span style="color:#FFB900;font-weight:600;font-size:11px;">{{selectedapx}}</span></p>
              <p v-if="selectedapx!='FREE'" style="font-size:10px;"><span style="color:#004B55;font-weight:600;font-size:11px;">{{selectedapx}}</span></p>
            </v-col>
            <v-col style="border-left:2px solid #004B55;height:30px;" class="px-1 py-0" cols=4>
              <p class="ma-0 " style="font-size:10px;">Equity delivery</p>
              <p  style="font-size:10px;"><span style="color:#FFB900;font-weight:600;font-size:11px;">₹ 0</span> Brokerage</p>
            </v-col>

            <!-- <p class="caption pt-2">* All the above prices are GST exclusive</p>   -->
          </v-row>
      <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
          <v-btn block style="color:white;" color="#FFB900"  @click="Submit()" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold">CONTINUE</v-btn>
          <v-progress-linear color="#004B55" :active="paymentdialog" indeterminate rounded height="3"></v-progress-linear>
      </div>

      </div>
            <!-- {{paymentSuccessPage}} -->
      <div v-if="paymentSuccessPage">
          <v-row no-gutters>
              <v-col cols=8>
              <h2  class="" style="color:#004B55;font-size:16px;">Pricing</h2>
              </v-col>
              <v-col cols=4 style="text-align:right;">
                <!-- <div style="float:right">
                    <v-chip small style="font-size:12px;">{{ pay_plan_type }}</v-chip>
                </div> -->
              </v-col>
            </v-row>
                <v-item-group mandatory class="pt-3">
                  <v-row>
            <!-- Toggle annually -->
                    <!-- <v-col>
                
                    <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;" >
                        <v-col  @click="selectedamc='FREE',selectedapi='FREE',selectedapx='FREE',planSelected='Startups'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Startup </h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                            <h1 style="float:right;font-size:18px;"><span>&#x20B9;</span> 499</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:600" >For Stock Market Learners</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:400;float:right;" >Per year billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5 py-2" >
                            <h6> <strong>&#x20B9; 10 / Order</strong><span class="ml-15 ml-lg-7" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item>

                    <v-item class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle" :color="active ? 'secondary white--text ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedamc='FREE',selectedapi='FREE',selectedapx='FREE',planSelected='Standard'" class="pa-0">
                        <v-row >
                          <v-col class="px-5 pa-0 pt-2">
                            <h2  style="font-size:16px;"  >Standard</h2>
                          </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 5999</h1>
                          </v-col>
                        </v-row>
                      <v-row >
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For Low frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2 style="font-size:10px;font-weight:400;float:right;" > Per year billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9; 5 / Order</strong><span class="ml-16 ml-lg-7" style="font-size:10px;font-weight:300;">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div
                            v-if="active"
                          >
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item>

                    <v-item v-if='!switchh' class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text  ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedapx='FREE',selectedamc='FREE',selectedapi='FREE',planSelected='Pro'" class="pa-0">
                        <v-row>
                          <v-col cols=3 class="pl-5 pe-0 pa-0 pt-2">
                            <h2  style="font-size:17px;"  >Pro</h2>
                          </v-col>

                           <v-col cols=4>
                                  <span  v-if="!switchh" class="caption input__label pr-2"  style="float:left;">Annually</span>
                                  <span  v-if="switchh" class="caption input__label pr-2"  style="float:left;">Monthly</span>
                                <span>
                                  <v-switch
                                      inset dense
                                      :hide-details="true"
                                      class="pa-0 align-end"
                                      color="secondary"
                                      style="margin:0px"
                                      @click="switch_screen()">
                                  </v-switch>
                                </span>
                            </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 11999</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For High frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2  style="font-size:10px;font-weight:400;float:right;" >Per month billed annually</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9;  1 / Order</strong><span class="ml-14 ml-lg-5" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div v-if="active">
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item>
                          
                    <v-item v-if='switchh' class="mt-4 py-4 px-2" v-slot="{ active, toggle }">
                      <v-card @click="toggle"  :color="active ? 'secondary white--text  ' : 'white'" style="box-shadow: 0px 0px 1px 1px #004B55;">
                        <v-col @click="selectedapx='FREE',selectedamc='FREE',selectedapi='FREE',planSelected='Pro'" class="pa-0">
                        <v-row>
                          <v-col cols=3 class="pl-5  pe-0 pa-0 pt-2">
                            <h2  style="font-size:17px;">Pro </h2>
                          </v-col>


                             <v-col cols=4>
                                  <span  v-if="!switchh" class="caption input__label pr-2"  style="float:left;">Annually</span>
                                  <span  v-if="switchh" class="caption input__label pr-2"  style="float:left;">Monthly</span>
                                  <span> <v-switch 
                                      inset dense
                                      :hide-details="true"
                                      class="pa-0 align-start"
                                      color="#FFB900"
                                      style="margin:0px"
                                      @click="switch_screen()"
                                    >
                                  </v-switch></span>
                            </v-col>
                          <v-col class="px-5 pa-0 pt-2">
                              <h1  style="float:right;font-size:18px;"><span>&#x20B9;</span> 1399</h1>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col class="pa-0 px-5">
                              <h2 style="font-size:10px;font-weight:600">For High frequency Traders</h2>
                        </v-col>
                        <v-col class="pa-0 px-5">
                          <h2  style="font-size:10px;font-weight:400;float:right;" >Per month billed monthly</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 py-2 px-5">
                              <h6><strong> &#x20B9;  1 / Order</strong><span class="ml-14 ml-lg-5" style="font-size:10px;font-weight:400">(Equity Intraday / FNO / Currency / MCX)</span></h6>
                        </v-col>
                      </v-row>
                      
                        <v-scroll-x-transition>
                          <div v-if="active">
                          </div>
                        </v-scroll-x-transition>
                        </v-col>
                      </v-card>
                    </v-item>


                  </v-col> -->
                </v-row>
            </v-item-group>
            <div class="pt-3" v-if="pay_plan!='Startup'" style="text-align:center;">
              <v-icon class="mx-auto ma-1" size="70" color="red" width="250" v-if="pay_status!='Paid'" id="hide">mdi-close-circle</v-icon>
              <v-icon class="mx-auto ma-1" size="70" color="green" width="250" v-if="pay_status=='Paid'" id="hide">mdi-checkbox-marked-circle</v-icon>
              <p style="margin-bottom:0;"><strong><span v-if="pay_status=='Paid'">SUCCESS</span><span v-else>FAILURE</span></strong><br>
              Payment {{ pay_status }}</p>
              <p style="font-size:30px;margin-bottom:0;">₹ <strong> {{ pay_amount }}</strong></p>
              <p style="font-size:14px">{{ pay_time }}</p>
              <v-divider></v-divider>
            </div>
            <div v-if="pay_plan!='Startup'">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>UPI ID</v-list-item-title>
                    <v-list-item-subtitle><p style="margin-bottom:0px" >{{ pay_upi }}</p></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                
                <!-- <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Bank Name</v-list-item-title>
                    <v-list-item-subtitle><p style="margin-bottom:0px" >ICICI  | 739276472457247</p></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
                
                <v-list-item two-line v-if="pay_status=='Paid'">
                  <v-list-item-content>
                    <v-list-item-title>UPI Transaction ID</v-list-item-title>
                    <v-list-item-subtitle><p style="margin-bottom:0px" >{{ pay_upi_transaction_id }}</p></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- 
                <v-list-item two-line>
                  <v-list-item-content >
                    <v-list-item-title>Payment ID</v-list-item-title>
                    <v-list-item-subtitle>{{ payment_id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
            </div>
            <!-- {{pay_status}} -->

        <div v-if="pay_status!='Paid' && razorpayCloseEvent">
            <v-alert
              border="left"
              dense
              colored-border
              type="error"
              elevation="2"
              style="text-align:justify;font-size:12px;"
            >
              If the transaction failed but the amount got deducted from the bank account. Banks usually take up to 3 business days to add money back to your account
            </v-alert>
            <!-- <router-link :to="'/pricing'" class="text-decoration-none"> -->

                <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
                    <v-btn block style="color:white;" color="#FFB900" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold" @click="retry()">RETRY</v-btn>
                  <v-progress-linear  color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
                </div>
            <!-- </router-link> -->
            </div>
             <div v-if="pay_status=='Paid'" class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
                    <v-btn block style="color:white;" color="#FFB900" class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold" @click="continuenext()">CONTINUE</v-btn>
                  <v-progress-linear  color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
              </div>
      </div>
  </v-col>
   
       <v-overlay :value="cardloader" absolute opacity="0.9">
            <v-row>
            <v-progress-circular
              color="#FFB900"
              indeterminate
              size="65"
              width="6"
              class="mx-auto"
            ></v-progress-circular>
      </v-row>
      <v-row class="mt-6">
        <p class="mb-0">Hold on! processing payment...</p>
      </v-row>
        </v-overlay>




</v-card>
</v-container>
</template>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<script>
  import axios from 'axios';
  import api from '@/api';
  const script = document.createElement('script')
  script.src = 'https://checkout.razorpay.com/v1/checkout.js'
  document.body.appendChild(script)
  export default {
  data(){
    return{
      selectedapi:'FREE',
      selectedamc:'FREE',
      selectedapx:'FREE',
      oid:null,
      cardloader:false,
      email:null,
      pan:null,
      selectedapc:null,
      Otp_screen:false,
      switchh:false,
      planSelected:'Startups',
      paymentdialog:false,
      payment_id:null,
      pay_status:null,
      paymentSuccessPage:false,
      razorpayCloseEvent:false,
      pay_amount:null,
      pay_time:null,
      pay_upi:null,
      pay_upi_transaction_id:null,
      pay_plan:null,
      pay_plan_type:null,
    }
  },
  methods:{
    continuenext (){
           this.$router.replace({name:'fileupload'})

    },
        open(){
          this.Otp_screen=!this.Otp_screen;
        },
        switch_screen(){
          this.switchh=!this.switchh
        },
        async Submit () {
          //this.paymentdialog=true;
          //this.paymentprocess = true;
          var razorpay_id ="rzp_live_pssp7KoJmWIsMm";
          var type = null;
          // console.log("Plan :"+this.planSelected,type,amount)
          // if(this.planSelected != 'Startup')
          // {
           
            if(this.switchh){
              type = 'Monthly'
            }
            else{
              type = 'Annually'
            }
            var amount='0'
            if(this.planSelected == 'Startups'){
              amount =Math.round(((499*0.18)+499)).toString();
            }
            if(this.planSelected == 'Standard'){
              amount =Math.round(((5999*0.18)+5999)).toString();
            }
            if(this.planSelected == 'Pro' && this.switchh){
              amount =Math.round(((1399*0.18)+1399)).toString();
            }
            if(this.planSelected == 'Pro' && !this.switchh){
              amount =Math.round(((11999*0.18)+11999)).toString();
            }
            // if(this.planSelected == 'Pro+' && !this.switchh){
            //   amount = ((11988*0.18)+11988).toString();
            // }
            // if(this.planSelected == 'Pro+' && this.switchh){
            //   amount = ((1399*0.18)+1399).toString();
            // }

            // console.log("Plan :"+this.planSelected,type,amount)
            var val= this;
            var config = {
              method: 'post',
              url:api.api_url+'/razorpay?amount='+amount+'&plan='+this.planSelected+'&type='+type+'&mob='+atob(localStorage.getItem('hash')),
              headers: { }
            };
              //https://awsbe.invezt.in
            await axios(config)
            .then(function (response) {
           //   console.log(JSON.stringify(response.data));
              val.oid = response.data['data']['id'];
              val.email=response.data['mail'];
              val.pan=response.data['pan'];
              //  console.log("Response Id:",response.data['pan'],response.data['mail'],response.data['data']['id']);
            })
            .catch(function (error) {
              console.log(error);
              alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
            });


            //console.log('Oid from Out :',this.oid,val.oid)
            //setTimeout(() => (this.loading = false), 2000)
          
            //console.log(val.oid);
           // this.loading = false;
            var order_id = val.oid;
            var mail  = val.email;
            var pan = val.pan;
            var mob_no = atob(localStorage.getItem('hash'));
            var options = {
                      "key": razorpay_id, // Enter the Key ID generated from the Dashboard
                      "amount": (parseInt(amount)*100).toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                      "currency": "INR",
                      "name": "Invezt E-Kyc",
                      "description": val.planSelected +" charges "+type,
                      "image": "https://zebuetrade.com/static/logo/Logo.svg",
                      "order_id": order_id,
                      //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                      "handler": function (response){
                          val.payment_id = response.razorpay_payment_id;
                          val.paymentStatusCheck();
                      },
                      "prefill": {
                          "email": mail,
                          "contact": mob_no
                      },
                       "notes": {
                        "email":mail,
                        "mobile_no": mob_no,
                        "pan":pan
                    },
                     "config": {
                     "display": {
                             "hide": [{"method": "paylater"},{"method": "wallet"},{"method": "cardless_emi"},{"method": "app"}],
                     }
                     },
                      "theme": {
                          "color": "#3399cc"
                      },
                    "modal": {
                    "ondismiss": function(){
                       // val.paymentSuccessPage=true
                       // console.log(' Checkout form closed ');
                        val.razorpayCloseEvent=true
                        val.paymentSuccessPage=false
                    }
                },
            };
            var rzp1 = new Razorpay(options);
            rzp1.open();
            //this.preventDefault();
            rzp1.on('payment.failed', function (response){
                        //alert('From Payment Failed function');
                        console.log("Error :"+response);
                        val.paymentSuccessPage = false;
                        var prop = [];
                        prop.push('fail');
                        //console.log(val.acc_no);
                        prop.push(response.error.metadata.payment_id);
                        prop.push(val.acc_no);
                        prop.push(val.client_code);
                        val.paymentdialog=false;
                        val.switchh=val.switchh;
                        //val.planSelected=
                        //val.payment_id = response.error.metadata.payment_id;
                       // val.paymentStatusCheck();
                   
                });
        },
        paymentStatusCheck(){
            var axiosThis= this;
             axiosThis.cardloader=true;
            var config = {
              method: 'post',
              url: api.api_url+'/payment_status?payment_id='+this.payment_id+'&status='+this.pay_status+'&mob='+atob(localStorage.getItem('hash')),
              headers: { }
            };

            axios(config)
            .then(function (response) {
              axiosThis.cardloader=false;
              axiosThis.paymentSuccessPage=true;
              console.log("Response paymentStatusCheck :"+response.data)
              axiosThis.pay_amount = response.data['amount']/100;
              if (response.data['status']== 'captured'){ 
                axiosThis.pay_status = 'Paid'
              }
              else{
                axiosThis.pay_status = 'Not Paid'
              }
              axiosThis.pay_time= Date(parseInt(response.data['created_at'])).split('GMT')[0];

              
                if(response.data['method']=="upi"){
                        axiosThis.paymentmethod='upi'
                        axiosThis.pay_upi = response.data['vpa']
                        if(response.data['acquirer_data']['upi_transaction_id']){
                            axiosThis.pay_upi_transaction_id = response.data['acquirer_data']['upi_transaction_id']
                        }else{
                             axiosThis.pay_upi_transaction_id = response.data['acquirer_data']['rrn']
                        }
                        axiosThis.pay_plan = response.data['description'].split(' ')[0]
                        axiosThis.pay_plan_type= response.data['description'].split(' ')[3]
               }
               else if(response.data['method']=="netbanking"){
                        axiosThis.paymentmethod='netbanking'
                        //axiosThis.pay_upi = response.data['vpa']
                        axiosThis.pay_upi_transaction_id = response.data['acquirer_data']['bank_transaction_id']
                        axiosThis.pay_plan = response.data['description'].split(' ')[0]
                        axiosThis.pay_plan_type= response.data['description'].split(' ')[3]

               }else if(response.data['method']=="card"){
                    axiosThis.paymentmethod='card'
                    //axiosThis.pay_upi = response.data['vpa']
                    axiosThis.pay_upi_transaction_id = response.data['acquirer_data']['auth_code']
                    axiosThis.pay_plan = response.data['description'].split(' ')[0]
                    axiosThis.pay_plan_type= response.data['description'].split(' ')[3]
               }
              // axiosThis.pay_upi = response.data['vpa']
              // axiosThis.pay_upi_transaction_id = response.data['acquirer_data']['rrn']
              // axiosThis.pay_plan = response.data['description'].split(' ')[0]
              // axiosThis.pay_plan_type= response.data['description'].split(' ')[3]
              // console.log(response.data['description'].split(' ')[0])
              //axiosThis.$router.replace({name:'Aadhar'})
            })
            .catch(function (error) {
              console.log(error);
              alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
            });
        },
        retry(){
          this.paymentSuccessPage=false;
        }
  },
  mounted(){
        var config = {
          method: 'post',
          url: api.api_url+'/payment_status?mob='+atob(localStorage.getItem('hash')),
          headers: { }
        };
        var axiosThis = this 
        axios(config)
        .then(function (response) {
           console.log("mounted response == ", (response.data));
            var data = response.data;
            if (data['amount']){ 
              axiosThis.cardloader=false
              if(data['payment_status']=="Paid"){ 
                axiosThis.paymentSuccessPage = true;
                
                axiosThis.pay_amount=(JSON.parse(data["payment_response"]).amount)/100
                axiosThis.pay_status = data['payment_status']
                axiosThis.pay_plan_type = data['plantype']
                axiosThis.pay_plan = data['plan']

                var payment_response = JSON.parse(data['payment_response']);
                console.log("Pay: mounted ",(payment_response))
                axiosThis.pay_time= Date(parseInt(payment_response['created_at'])).split('GMT')[0];

                     if(payment_response.method=="upi"){
                        //axiosThis.paymentmethod='upi'
                        axiosThis.pay_upi = payment_response['vpa']
                         if(payment_response.acquirer_data.upi_transaction_id){
                            axiosThis.pay_upi_transaction_id =payment_response.acquirer_data.upi_transaction_id
                        }else{
                             axiosThis.pay_upi_transaction_id = payment_response.acquirer_data.rrn
                        }
                       }
                       else if(payment_response.method=="netbanking"){
                                    axiosThis.paymentmethod='netbanking'
                                    axiosThis.pay_upi_transaction_id = payment_response.acquirer_data.bank_transaction_id
                          
                        }else if(payment_response.method=="card"){
                                axiosThis.paymentmethod='card'
                                axiosThis.pay_upi_transaction_id = payment_response.acquirer_data.auth_code
                               
                        } 
               
              }else{
                axiosThis.pay_status=data['payment_status']
                //console.log("data['payment_status'] ",data['payment_status'])
              }
            }
        })
        .catch(function (error) { 
          console.log(error);
          alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
        });

        history.pushState(null, null, location.href);
              window.onpopstate = function () {
              history.go(1);  
          };
  }
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
*{
  font-family: 'Inter', sans-serif;
}
</style>