import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light:{
       
        secondary: '#004B55',    //green
       
      },
      dark:{  
         
          secondary:"#FFB900",   //yellow
          accent: '#8c9eff'
      },
    },
  },
})
export default vuetify