<template>
<v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">
<v-card tile  height="100%" width="450"  elevation="0" class="px-9 px-lg-12 mx-auto">
            <v-progress-linear absolute color='#004B55' height=10 value="86"></v-progress-linear>
            <div v-if="!ipvstatus">
                <v-col  class="pt-6 px-0 pb-0">
                    <v-img 
                        :src="require('@/assets/invezt.svg')"
                        contain
                        class="ml-n4 py-2 py-lg-0"
                        width="110" 
                        />
                        <h2  class="mt-1" style="color:#004B55;font-size:16px;">In Person Verification</h2>
                        <p  class="mt-1" style="font-size:14px;font-weight:500" >10 Sec Video Record with IPV authentication code reading</p>
                </v-col>

                <v-btn :disabled='cardloader' 
                    outlined
                    @click="cardloader=true,digioipv()"
                    width="100%" 
                    height="200px"
                    color="#FFB900"
                    class="mx-auto rounded-0"
                    icon>
                    <v-icon class="mt-n12" style="font-size:80px;">mdi-movie-open</v-icon>
                    <v-icon small style="margin-top:70px;font-size:14px" class="mb-0 ml-n6">click here to start IPV</v-icon>
                </v-btn>
                <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
            </div>
           
           
            <div v-if="ipvstatus">
                    <v-col  class="pt-6 px-0 pb-0">
                        <v-img 
                            :src="require('@/assets/invezt.svg')"
                            contain
                            class="ml-n4 py-2 py-lg-0"
                            width="110" 
                            />
                            <h2  class="mt-1" style="color:#004B55;font-size:16px;">In Person Verification</h2>
                    
                    </v-col>
                    <Media
                        :kind="'video'"
                        :isMuted="false"
                        :src="apiurl+'/ipv_video_get?pan='+pan"
                        :autoplay="false"
                        :controls="true"
                        :loop="false"
                       
                        :ref="'video_player'"
                        height="200px"
                        width="100%"
                    ></Media>
                    
                    <!-- otp : {{ otp }}<br>
                    created_at : {{ created_at }}<br>
                    completed_at : {{ completed_at }}<br>
                    updated_at : {{ updated_at }}<br>
                    otp: {{ otp }}<br>
                    status : {{ status }}<br>
                    location: {{ location }} -->


                     <v-stepper vertical elevation=0>
                        <v-stepper-step class="pl-1 pt-2" step="1" complete>
                            Start at
                            <small class="pt-2">{{ created_at }}</small>
                        </v-stepper-step>

                        <v-stepper-step class="pl-1 pt-2" step="2" complete>
                            IPV auth code
                            <small class="pt-2">{{ otp }}</small>
                        </v-stepper-step>

                        <v-stepper-step class="pl-1 pt-2" step="3" complete>
                            Status
                            <small class="pt-2">{{ status }}</small>
                        </v-stepper-step>

                        <v-stepper-step class="pl-1 pt-2" step="4" complete>
                            Location
                            <small class="pt-2">{{ location }}</small>
                        </v-stepper-step>

                        <v-stepper-step class="pl-1 pt-2" step="5" complete>
                            Completed at
                            <small class="pt-2">{{ completed_at }}</small>
                        </v-stepper-step>
                      
                    </v-stepper>

                <v-btn :disabled='cardloader'
                block
                style="color: white"
                color="#FFB900"
                class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
                @click="cardloader=true,serverConnect()"
                >CONTINUE</v-btn>
                <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
                <!--  -->
             </div>
</v-card>
</v-container>
</template>
<script src="https://app.digio.in/sdk/v9/digio.js"></script>
<script>
    
   import axios from 'axios';
   import Media from "@dongido/vue-viaudio";   
   import api from '@/api'; 
   // const ifscErrorDesc=(value,vm) =>vm.ifscErrorDesc 
   const script = document.createElement('script')
   script.src = 'https://app.digio.in/sdk/v9/digio.js'
   document.body.appendChild(script)
   export default {      
    data(){
      return {
          apiurl:api.api_url,
          cardloader:false,
          order_id:null,
          mail_id:null,
          accesstoken:null,
          ipvstatus:false,
          created_at:null,
          completed_at:null,
          verify:null,
          status:null,
          location:null,
          otp:null,
          updated_at:null,
          pan:null,
      }
    },
    components:{
        Media
    },
    methods: {

        digioipv(){      
                var axiosThis = this;              
                var options = {
                environment: "production",
                    "callback": function(t){
                        // console.log("t :: ",t);
                        if(t.hasOwnProperty('error_code')){axiosThis.cardloader=false;}
                        else{axiosThis.digio_fetch_file(t['digio_doc_id'])}
                    },
                    logo:"https://invezt.in/static/logo/Invezt-01.svg",
                    theme:{
                        primaryColor:"#004B55",
                        secondaryColor:"#FFB900",
                    }
                };
                    var digio = new Digio(options);
                    digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
                    digio.submit(this.order_id, this.mail_id,this.accesstoken);
        },
        digio_fetch_file(file_id){
                var config = {
                    method: 'post',
                    url: api.api_url+'/ipv_fetch?id='+file_id+'&mob='+atob(localStorage.getItem('hash')),
                    headers: { }
                };
                var axiosThis = this;
                axios(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.cardloader=false;
                    axiosThis.ipvstatus=true;
                    axiosThis.created_at=response.data['created_at'];
                    axiosThis.completed_at = response.data['completed_at'];
                    axiosThis.otp = response.data['otp']
                    axiosThis.status = response.data['status']
                    axiosThis.updated_at = response.data['updated_at']
                    axiosThis.location = response.data['location']
                    axiosThis.pan = response.data['pan']
                })
                .catch(function (error) {
                    console.log(error)
                    alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                });
        },
        serverConnect(){
            var mob = atob(localStorage.getItem('hash'))
            var config = {
            method: 'post',
            url: api.api_url+'/server_push?mob='+mob,
            headers: { }
        };
        var axiosThis = this 
        axios(config)
        .then(function (response) {
            if(response.data=='success'){
            axiosThis.cardloader = false;
            axiosThis.$router.push({name:'submit'})
            //console.log("Response_data ="+response.data,response.data['Status'])  
            }else{
                console.log("ipv error occured")
            }
        })
        .catch(function (error) { 
            console.log(error);
            alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
        });
        }
    },
    mounted(){

        this.cardloader=true;
        var mob = atob(localStorage.getItem('hash'))
        // console.log("Mobile:"+mob)
        var config = {
            method: 'post',
            url: api.api_url+'/ipv?mob='+mob,
            headers: { }
        };
        var axiosThis = this 
        axios(config)
        .then(function (response) {
            axiosThis.cardloader = false;
        //    console.log("Response_data =",response.data)  
            if(response.data['Status'] == 'Init Request'){
                axiosThis.order_id=response.data['id'];
                axiosThis.mail_id=response.data['email'];
                axiosThis.accesstoken=response.data['accesstoken']
            }
            else{
                    axiosThis.cardloader=false;
                    axiosThis.ipvstatus=true;
                    // var resp = response.data;
                    // console.log("Response Data:"+resp)
                    // console.log("Response Data:"+response,response.data.JSON(),typeof(response.data))
                    // var data = JSON.parse(resp);
                    axiosThis.created_at=response.data['created_at'];
                    axiosThis.completed_at = response.data['completed_at'];
                    axiosThis.otp = response.data['otp']
                    axiosThis.status = response.data['status']
                    axiosThis.updated_at = response.data['updated_at']
                    axiosThis.location = response.data['location']
                    axiosThis.pan = response.data['pan']
            }
        })
        .catch(function (error) { 
            console.log(error);
            alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
        });
                        
    },
        
  }
</script>

