<template>
  <v-container
    class="pa-0"
    id="font"
    fluid
    fill-height
    style="background-color: #f0f5fa"
  >
    <v-card
      v-if="acceptannexueScreen"
      id="pdfpageheight"
      class="mx-auto overflow-y-auto"
      :width="!$vuetify.breakpoint.mobile ? 'calc(100vw - 40%)' : '100vw'"
    >
      <v-progress-linear
        v-if="overlaypdf"
        class="mt-3 pt-3"
        color="#004B55"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <pdf
        v-for="i in numPages"
        :key="i"
        :page="i"
        @page-loaded="
          $event == numPages ? (overlaypdf = false) : (overlaypdf = false)
        "
        src="Disclosure_and_Tariff.pdf"
      >
      </pdf>

      <div
        class="mb-md-3 mx-auto px-lg-12"
        style="
          position: fixed;
          bottom: 10px;
          right: 30px;
          display: inline-block;
        "
      >
        <v-btn
          solo
          class="me-5"
          color="#FFB900"
          style="color: white"
          @click="
            (acceptannexueScreen = false),
              (agreement3 = false),
              checkforselection()
          "
          >back</v-btn
        >
        <v-btn
          :disabled="isScrolledtoBottom"
          solo
          style="color: white"
          color="#FFB900"
          v-model="agreement3"
          @click="acceptagrement()"
          >Accept</v-btn
        >
      </div>
    </v-card>

    <v-card
      v-else
      tile
      height="100%"
      width="450"
      elevation="0"
      class="px-9 px-lg-12 mx-auto"
    >
      <v-progress-linear
        absolute
        color="#004B55"
        height="10"
        value="86"
      ></v-progress-linear>
      <v-col class="pt-6 px-0 pb-0">
        <v-img
          :src="require('@/assets/invezt.svg')"
          contain
          class="ml-n4 py-2 py-lg-0"
          width="110"
        />
        <h2 class="mt-1" style="color: #004b55; font-size: 16px">
          Exchange Selection
        </h2>

        <p class="mb-0 mt-4">
          Stocks and Derivatives trade on
          <strong style="color: #004b55; font-size: 16px">NSE</strong> and
          <strong style="color: #004b55; font-size: 16px">BSE</strong>
        </p>
        <v-row class="my-3 ml-0">
          <v-checkbox
            v-model="nb_equity"
            hide-details
            color="#004B55"
            class="ma-0"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="5" md="2" class="pt-0 px-1 pa-md-0">
            <p
              style="font-size: 15px; text-align: justify"
              class="ma-0 mt-1 ml-n1"
            >
              Equity
            </p>
          </v-col>

          <v-checkbox
            v-model="nb_fno"
            hide-details
            color="#004B55"
            class="ma-0 ml-n4"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="5" md="1" class="pt-0 px-1 pa-md-0">
            <p
              style="font-size: 15px; text-align: justify"
              class="ma-0 mt-1 ml-n1"
            >
              F&O
            </p>
          </v-col>

          <v-checkbox
            v-model="nb_currency"
            hide-details
            color="#004B55"
            class="ma-0"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="4" md="2" class="pt-0 px-1 pa-md-0">
            <p
              style="font-size: 15px; text-align: justify"
              class="ma-0 mt-1 ml-n1"
            >
              Currency
            </p>
          </v-col>

          <v-checkbox
            v-model="nb_commodity"
            hide-details
            color="#004B55"
            class="ma-0 ml-md-0 ml-3"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="4" md="3" class="pt-0 px-1 pa-md-0">
            <p
              style="font-size: 15px; text-align: justify"
              class="ma-0 mt-1 ml-lg-n1"
            >
              Commodity
            </p>
          </v-col>
        </v-row>

        <p class="pt-4">
          Commodities trade on
          <strong style="color: #004b55; font-size: 16px">MCX</strong>
        </p>
        <v-row class="pb-3 ml-0">
          <v-checkbox
            v-model="m_commodity"
            hide-details
            color="#004B55"
            class="ma-0"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="4" class="pa-0">
            <p
              style="font-size: 15px; text-align: justify"
              class="ma-0 mt-1 ml-n1"
            >
              Commodity
            </p>
          </v-col>

          <!-- <v-checkbox 
                    v-model="checkboxaccept"
                    hide-details
                    color='#004B55'
                    class="ma-0"
                    :rules="checkrules"
                    required
                ></v-checkbox>
                 <v-col cols=4 class="pa-0">
                    <p style="font-size:15px;text-align:justify" class="ma-0 mt-1 ml-n1">All</p>
                </v-col> -->
        </v-row>

        <v-divider class="mt-5"></v-divider>
        <v-row class="ma-0 pt-5">
          <v-checkbox
            v-model="agreement1"
            hide-details
            color="#004B55"
            class="ma-0"
            @click="checkforselection()"
            required
          ></v-checkbox>

          <v-col cols="10" class="pa-0">
            <p style="font-size: 15px; text-align: justify" class="ma-0 mt-1">
              I'am an Indian citizen and tax resident of India and of no other
              country
            </p>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-checkbox
            v-model="agreement2"
            hide-details
            class="ma-0"
            color="secondary"
            @click="checkforselection()"
            required
          ></v-checkbox>
          <v-col cols="10" class="pa-0">
            <p style="font-size: 15px; text-align: justify" class="ma-0 mt-1">
              I agree to open a trading and demat account
            </p>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-checkbox
            hide-details
            :input-value="agreement3"
            class="ma-0"
            color="secondary"
            @click="checkforselection(),(acceptannexueScreen = true)"
            required
          ></v-checkbox>
          <v-col cols="10" class="pa-0" style="max-height: 15px">
            <p style="font-size: 15px" class="ma-0 mt-1 me-n1">
              I confirm to have and understood the contents of
              <a
                class="font-weight-bold text-decoration-none"
                style="color: #004b55"
                rel="noopener nofollow"
                href="https://zebuetrade.com/static/Downloads/download_docs/kyc/trading-disclosure-document.pdf"
                target="_blank"
                >Equity Annexure</a
              >
              and
              <a
                class="font-weight-bold text-decoration-none"
                rel="noopener nofollow"
                style="color: #004b55"
                href="https://zebuetrade.com/static/Downloads/download_docs/kyc/COM_Disclosure.pdf"
                target="_blank"
                >Commodity Annexure</a
              >
              documents
            </p>
          </v-col>
        </v-row>

        <div
          class="pb-3 mx-auto px-lg-12"
          style="
            position: fixed;
            bottom: 0;
            left: 0px;
            right: 0px;
            display: inline-block;
            width: 450px;
            background-color: white;
          "
        >
          <v-btn
            :disabled="cardloader || submitbtn"
            block
            style="color: white"
            color="#FFB900"
            class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
            @click="segment_selection()"
            >CONTINUE</v-btn
          >
          <v-progress-linear
            color="#004B55"
            :active="cardloader"
            indeterminate
            rounded
            height="3"
          ></v-progress-linear>
        </div>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "@/api";
import pdf from "vue-pdf";

var loadingTask = pdf.createLoadingTask("Disclosure_and_Tariff.pdf");
export default {
  components: { pdf },
  data() {
    return {
      forstudent: false,
      heisStudent: false,
      cardloader: false,
      submitbtn: true,
      nb_equity: false,
      nb_fno: false,
      nb_currency: false,
      nb_commodity: false,
      m_commodity: false,
      agreement1: false,
      agreement2: false,
      agreement3: false,

      src: loadingTask,
      numPages: undefined,
      currentPage: 0,
      pageCount: 0,
      overlaypdf: true,
      isScrolledtoBottom: true,
      offsetTop: 0,
      acceptannexueScreen: false,
    };
  },
  methods: {
    onScroll(e) {
      const { offsetHeight } = e.target.body;
      if (offsetHeight - 2000 >= window.scrollY) {
        this.isScrolledtoBottom = true;
      } else  {
        this.isScrolledtoBottom = false;
      } //if (window.scrollY > 300)
    },

    acceptagrement() {
      this.agreement3 = true;
      this.checkforselection();
      this.acceptannexueScreen = false;
    },

    checkforselection() {
      if (
        (this.nb_equity ||
          this.nb_fno ||
          this.nb_currency ||
          this.nb_commodity ||
          this.m_commodity) &&
        this.agreement1 &&
        this.agreement2 &&
        this.agreement3
      ) {
        this.submitbtn = false;
      } else {
        this.submitbtn = true;
      }
    },

    segment_selection() {
      // console.log(this.nb_equity)
      if (
        (this.nb_equity ||
          this.nb_fno ||
          this.nb_currency ||
          this.nb_commodity ||
          this.m_commodity) &&
        this.agreement1 &&
        this.agreement2 &&
        this.agreement3
      ) {
        this.cardloader = true;

        var config = {
          method: "post",
          url:
            api.api_url +
            "/segmentUpdation?mob=" +
            atob(localStorage.getItem("hash")) +
            "&nb_equity=" +
            this.nb_equity +
            "&nb_fno=" +
            this.nb_fno +
            "&nb_currency=" +
            this.nb_currency +
            "&nb_commodity=" +
            this.nb_commodity +
            "&m_commodity=" +
            this.m_commodity,
          headers: {},
        };
        var axiosThis = this;
        axios(config)
          .then(function (response) {
            console.log("Main response == ", response.data);
            var respdata=response.data
            axiosThis.cardloader = false;
            if (respdata["status"]=="success"){
            //  if (axiosThis.heisStudent) {
              axiosThis.$router.push({ name: "fileupload" }); // @payment is free untill 2023 
            // } else {
            //   axiosThis.$router.push({ name: "payment" });
            // }

            }else{
                alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
            }
          })
          .catch(function (error) {
            console.log(error);
            alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
          });
      }
    },
  },
  mounted() {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    //   console.info(this.numPages);
    });
    var config = {
      method: "post",
      url:
        api.api_url +
        "/segmentUpdation?mob=" +
        atob(localStorage.getItem("hash")),
      headers: {},
    };
    var axiosThis = this;
    axios(config)
      .then(function (response) {
        var data = response.data;
        console.log(typeof data, data);
        var NSE = data["NSEseg"];
        var MCX = data["MCXseg"];
        var youngster = data["youngster"];
        
        if (NSE) {
          if (NSE.split(",").includes("Cash")) {
            axiosThis.nb_equity = true;
          }
          if (NSE.split(",").includes("Currency")) {
            axiosThis.nb_currency = true;
          }
          if (NSE.split(",").includes("F&O")) {
            axiosThis.nb_fno = true;
          }
          if (NSE.split(",").includes("Commodity")) {
            axiosThis.nb_commodity = true;
          }
          axiosThis.agreement1 = true;
          axiosThis.agreement2 = true;
          axiosThis.agreement3 = true;
          axiosThis.submitbtn = false;
        }
        if (MCX=="True") {
          axiosThis.m_commodity = true;
          axiosThis.agreement1 = true;
          axiosThis.agreement2 = true;
          axiosThis.agreement3 = true;
          axiosThis.submitbtn = false;
        }
        if (youngster == true) {
          axiosThis.forstudent = true;
          axiosThis.heisStudent = true;
         
        }
        
      })
      .catch(function (error){
        console.log(error);
        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
      });
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scope>
.v-input__append-inner > .v-input__icon > .v-icon {
  font-size: 14px !important;
  color: #377dff;
}
body {
  touch-action: auto !important;
}
</style>
