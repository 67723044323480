<template>
<v-container class="pa-0" id="font" fluid fill-height style="background-color:#F0F5FA">


<v-card height="100%" width="450"  elevation="0" class="px-9 px-lg-12 mb-16 mb-md-4 mx-auto">
     <v-progress-linear absolute color='#004B55' height=10 value="87.5"></v-progress-linear>
<v-col class="pt-6 px-0 mb-16 mb-md-4">
      <v-img 
        :src="require('@/assets/invezt.svg')"
        contain
        class="ml-n4 py-2 py-lg-0"
        width="110" 
        />
        <h2  class="mt-1" style="color:#004B55;font-size:16px;">Upload Your Documents</h2>

        <v-row class="pb-0 pt-6">
            
                <v-col v-if="!photoreject" cols=12 md=6   class="py-0"> 
                      <v-file-input 
                          outlined
                          small-chips
                          truncate-length="14"
                          color='#004B55'
                          label="Upload Photo"
                          v-model="photoFile"
                          accept="image/png, image/jpg, image/jpeg"
                          prepend-icon=""
                          :append-icon='photoFile? "" : "mdi-account-box"'
                          clear-icon='mdi-trash-can-outline color-red'
                          :error-messages="photoFErrors"
                          @input="$v.photoFile.$touch()"
                          @blur="$v.photoFile.$touch()"
                          required 
                      ></v-file-input>
                </v-col>
                
                
                <v-col v-if="!signreject" cols=12 md=6  class="py-0">
                      <v-file-input 
                          small-chips
                        outlined
                          truncate-length="14"
                          prepend-icon=""
                          color='#004B55'
                          v-model="signFile"
                          accept="image/png, image/jpg, image/jpeg"
                          label="Signature"
                          :append-icon='signFile?"":"mdi-draw-pen"'
                           clear-icon='mdi-trash-can-outline'
                          :error-messages="signFErrors"
                          @input="$v.signFile.$touch()"
                          @blur="$v.signFile.$touch()"
                          required 
                      ></v-file-input>
              </v-col>
        </v-row>
      
        <v-row>
              <v-col v-if="!panreject" cols=12 md=12  class="py-0">
                        <v-file-input 
                        small-chips
                        outlined
                        truncate-length="20"
                        color='#004B55'
                        prepend-icon=""
                        :append-icon='panFile?"":"mdi-card-account-details-outline"'
                         clear-icon='mdi-trash-can-outline'
                        label="PAN" 
                        v-model="panFile"
                        accept="image/png, image/jpg, image/jpeg"
                        :error-messages="panfErrors"
                        @input="$v.panFile.$touch()"
                        @blur="$v.panFile.$touch()"
                        required 
                        ></v-file-input>
                </v-col>

              <!-- <v-col cols=12 md=6  class="py-0">
                    <v-file-input 
                        small-chips
                        outlined
                        truncate-length="14"
                        prepend-icon=""
                        color='#004B55'
                        v-model="aadharFile"
                        label="Aadhar"
                        accept="image/png, image/jpg, image/jpeg,application/pdf"
                        :error-messages="aadharFErrors"
                        @input="$v.aadharFile.$touch()"
                        @blur="$v.aadharFile.$touch()"
                        required 
                    ></v-file-input>
              </v-col> -->
        </v-row>

               <v-row>
                     <v-col v-if="!bankreject" cols=12 md=6 class="py-0">
                        <v-select 
                          color='#004B55'
                          :items="BankProofitems"
                          v-model="BankProofselect"
                          placeholder="Bank Proof Type"
                          :error-messages="BankSelectErrors"
                          @input="$v.BankProofselect.$touch()"
                          @blur="$v.BankProofselect.$touch()"
                          required 
                          ></v-select>
                      </v-col>

                      <v-col v-if="!bankreject"  cols=12 md=6 class="py-0" > 
                          <v-file-input 
                          outlined
                          small-chips
                          truncate-length="14"
                          @change="bankProofCheck()"
                          color='#004B55'
                          prepend-icon=""
                          label="Bank Proof"
                          :append-icon='bankFile?"":"mdi-bank"'
                          clear-icon='mdi-trash-can-outline'
                          v-model="bankFile"
                          accept="image/png, image/jpg, image/jpeg,application/pdf"
                          :error-messages="bankfErrors"
                          @input="$v.bankFile.$touch()"
                          @blur="$v.bankFile.$touch()"
                          required
                          ></v-file-input>
                    </v-col>
                </v-row>
                <v-row v-if="bankPassRequired" class="pb-2">
                      <v-col cols=9 class="pt-0  pb-0 pe-0">
                          <v-text-field dense
                          :disabled="!!disablebank"
                            outlined
                            :color="errorbankpass?'red':''"
                            :error="errorbankpass"
                            :type="showbankpass ? 'text' : 'password'"
                            v-model="bankpass"
                            prepend-icon=""
                            hide-details
                            label="PDF Password"
                            :append-icon="showbankpass ? 'mdi-eye' : 'mdi-eye-off'"
                            small-chips
                            required
                            @click:append="showbankpass=!showbankpass"
                          >
                        </v-text-field>
                        <p v-if="errorbankpass"  style="color:red;font-size:14px;margin-bottom:5px">{{bankpasserror}}</p>
                     </v-col>
                      <v-col cols="3" class="pt-0 ">  
                        <v-btn  text depressed class="px-1"  @click="verifyBankPassword()">check</v-btn>
                    </v-col>       
                </v-row>


                <v-row>
                    <v-col v-if="!incomereject" cols=12 md=6 class="py-0">
                        <v-select 
                            color='#004B55'
                            :items="IncomeProofitems"
                            v-model="IncomeProofselect"
                            :error-messages="IncomeSelectErrors"
                            @input="$v.IncomeProofselect.$touch()"
                            @blur="$v.IncomeProofselect.$touch()"
                            required
                            placeholder="Income Proof Type"
                        ></v-select>
                    </v-col>

                  <v-col v-if="!incomereject" cols=12 md=6 class="py-0">
                      <v-file-input 
                          outlined
                          truncate-length="14"
                          v-model="incomeproofFile"
                          prepend-icon=""
                          @change="financialIncomeCheck()"
                          color='#004B55'
                          label="Income Proof"
                          :append-icon='incomeproofFile?"":"mdi-cash"'
                          clear-icon='mdi-trash-can-outline'
                          small-chips
                          accept="application/pdf"
                          :error-messages="IncomeFErrors"
                          @input="$v.incomeproofFile.$touch()"
                          @blur="$v.incomeproofFile.$touch()"
                          required
                      ></v-file-input>
                  </v-col>
          </v-row>
          
          <v-row v-if="incomePassRequired">
                    <v-col cols=9 class="pt-0  pb-10 pe-0">
                       <v-text-field
                        :disabled="!!disableincome"
                          outlined
                          dense
                          :color="errorincomepass?'red':''"
                          :error="errorincomepass"
                          :type="showincomepass ? 'text' : 'password'"
                          v-model="incomepass"
                          prepend-icon=""
                          hide-details
                          label="PDF Password"
                          :append-icon="showincomepass ? 'mdi-eye' : 'mdi-eye-off'"
                          small-chips
                          required
                          @click:append="showincomepass=!showincomepass"
                        >
                      </v-text-field>
                      <p v-if="errorincomepass"  style="color:red;font-size:14px;">{{incomepasserror}}</p>
                  </v-col>
                   <v-col cols="3" class="pt-0 ">  
                      <v-btn text depressed class="px-1"  @click="verifyIncomePassword()">check</v-btn>
                  </v-col>
        </v-row>
          
</v-col>

<v-snackbar class="mb-16" v-model="snackbar" color="#002d33">
      <b>{{ toasttext }}</b>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#FFB900"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    
       <div class="pb-3 mx-auto px-lg-12" style="position:fixed;bottom:0;left:0px;right:0px;display:inline-block;width:450px;background-color:white;">
            <v-btn :disabled='allsetforBANKpass() || allsetforincomepass() || $v.$invalid || cardloader'
              block
              style="color: white"
              color="#FFB900"
              class="mt-3 pl-0 pe-12 pe-md-3 font-weight-bold"
              @click="fileUpload()"
            >CONTINUE</v-btn>
            <v-progress-linear color="#004B55" :active="cardloader" indeterminate rounded height="3"></v-progress-linear>
       </div>
</v-card>
</v-container>
</template>

<script>
  import axios from 'axios';
  import api from '@/api';
  import { validationMixin } from 'vuelidate'
  import {required} from 'vuelidate/lib/validators'
  const mustcheck = (value) =>!value || value.size <=4194304;
  const PDFCheckRule =(value)=>!value || value.type==='application/pdf'

 export default {
      mixins: [validationMixin],
      
    data(){
      return {
            photoreject:false,
            signreject:false,
            panreject:false,
            bankreject:false,
            incomereject:false,

            bankdata:false,
            photoFile:null,
            signFile:null,
            panFile :null, 
            BankProofselect:null,
            bankFile:null,
            IncomeProofselect:null,
            incomeproofFile:null,
            BankProofitems:['Personalized Cheque','Bank Statement','Bank Passbook','Bank Letter'],         
            IncomeProofitems:['Latest 6 months Bank Statement','Latest ITR Copy','Latest 6 months salary slip','DP holding statement as on date','Net worth Certificate','Copy of Form 16 in case of salary income'],
            cardloader:false,
            toasttext:'',
            snackbar:false,

            incomePassRequired:false,
            incomepass:'',
            showincomepass:true,
            errorincomepass:true,
            incomepasserror:'',
            disableincome:false,

            bankPassRequired:false,
            bankpass:'',
            showbankpass:true,
            errorbankpass:true,
            bankpasserror:'',
            disablebank:false,
          }
    },
    validations() {
      var returnData={}

      if(!this.photoreject){
        returnData["photoFile"]={required,mustcheck}
      }
      if(!this.signreject){
        returnData["signFile"]={required,mustcheck}
      }
      if(!this.panreject){
        returnData["panFile"]={required,mustcheck}
      }
      if(!this.bankreject){
        returnData["BankProofselect"]={required}
        returnData["bankFile"]={required,mustcheck}
      }
      if(!this.incomereject) {
        returnData["IncomeProofselect"]={required}
        returnData["incomeproofFile"]={required,mustcheck,PDFCheckRule}
      }
      return returnData
      },

    created(){
          var  data=JSON.parse(sessionStorage.getItem('rejectlist'))
              if(data !=null){
                    console.log(data['rejectlist'])
                              this.photoreject=true
                              this.signreject=true
                              this.panreject=true
                              this.bankreject=true
                              this.incomereject=true
                    for (var i = 0; i < data['rejectlist'].length; i++) {
                        if (data['rejectlist'][i] == 'pan') {                                                 
                                  this.panreject=false
                        } else if (data['rejectlist'][i] == 'signature') {
                                  this.signreject=false
                        } else if (data['rejectlist'][i] == 'bank') {
                                  this.bankreject=false                             
                        } else if (data['rejectlist'][i] == 'image') {
                                  this.photoreject=false
                        } else if (data['rejectlist'][i] == 'finan') {
                                  this.incomereject=false
                        }
                    }
                  }

                  else{
                      var config = {
                        method: 'post',
                        url:api.api_url+'/check_file?mob='+atob(localStorage.getItem('hash')),
                        headers: {}
                      };
                    var axiosThis = this
                    axios(config)
                    .then(function (response) {
                      // console.log("mounted response : ",response.data)
                      if(!!response.data["Bank File"] && !!response.data["Sign File"] && !!response.data["Photo File"] && !!response.data["Pan File"]){ // && !!response.data["Income File"]
                       console.log("done")
                       axiosThis.$router.push({name:'ipv'})
                      }

                      if(response.data["selected_segment_comm"]=="False"){
                            axiosThis.incomereject=true
                      }
                      
                      if ("pan_Status" in response.data && response.data["pan_Status"]){
                            var panresponse=JSON.parse(response.data["pan_Status"])
                           
                      if("pan" in panresponse && panresponse["pan"]==true){
                          axiosThis.panreject=true
                      }
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      axiosThis.cardloader=false;
                      alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                    });
                  }
             },

    methods:{
        allsetforincomepass(){
                        if(this.incomePassRequired && !this.errorincomepass){
                           return false
                        }else if(!this.incomePassRequired && this.errorincomepass){
                           return false
                        }else{
                          return true
                        }          
                       },
        allsetforBANKpass(){
                        if(this.bankPassRequired && !this.errorbankpass){
                           return false
                        }else if(!this.bankPassRequired && this.errorbankpass){
                           return false
                        }else{
                          return true
                        }          
                       },

        financialIncomeCheck(){
                  if(this.incomeproofFile && (this.incomeproofFile['type'] == 'application/pdf')){
                    let formData = new FormData();
                      formData.append('financial', this.incomeproofFile);
                      var axiosThis = this;
                      axios.post(api.api_url+'/financialCheck/'+atob(localStorage.getItem('hash')), formData, {
                            headers: {'Content-Type': 'multipart/form-data'}
                            }).then(function (response) {
                       console.log("incomefile response : ",response.data)
                       if(response.data['data']==true){
                            axiosThis.incomePassRequired=true  
                       }
                      })
                      .catch(function (error) {
                        console.log(error);
                        axiosThis.cardloader=false;
                        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                      });
                }
                this.incomePassRequired=false
            },

        verifyIncomePassword(){
               if(this.incomepass){
                    let formData = new FormData();
                      formData.append('financial',this.incomeproofFile);
                      var axiosThis = this;
                      axios.post(api.api_url+'/verifyIncomePassword/'+this.incomepass, formData, {
                            headers: {'Content-Type': 'multipart/form-data'}
                                      })
                      .then(function (response) {
                      // console.log("incomepass response : ",response.data)
                       if(response.data['data']==false){
                         axiosThis.incomepasserror="Invalid password"
                         axiosThis.errorincomepass=true
                          return false
                       }else if(response.data['data']==true){
                                axiosThis.errorincomepass=false
                                axiosThis.incomepasserror=''
                                 //axiosThis.disableincome=!axiosThis.disableincome
                                 return true
                       }else{
                          
                           axiosThis.incomepasserror='Password required'
                            return true
                       }
                      })
                      .catch(function (error) {
                        console.log(error);
                        axiosThis.cardloader=false;
                        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                      });
                }
            },

        bankProofCheck(){
                  if(this.bankFile){
                    let formData = new FormData();
                      formData.append('bankproof', this.bankFile);
                      var axiosThis = this;
                      axios.post(api.api_url+'/bankCheck/'+atob(localStorage.getItem('hash')), formData, {
                            headers: {'Content-Type': 'multipart/form-data'}
                          }).then(function (response) {
                       console.log("bankfile response : ",response.data)
                       if(response.data['data']==true){
                          axiosThis.bankPassRequired=true
                       }
                      })
                      .catch(function (error) {
                        console.log(error);
                        axiosThis.cardloader=false;
                        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                      });
                }
                this.bankPassRequired=false
            },
        verifyBankPassword(){
               if(this.bankpass){
                    let formData = new FormData();
                      formData.append('bankproof', this.bankFile);
                      var axiosThis = this;
                      axios.post(api.api_url+'/verifyBankPassword/'+this.bankpass, formData, {
                            headers: {'Content-Type': 'multipart/form-data'}
                                      })
                      .then(function (response) {
                      // console.log("incomepass response : ",response.data)
                       if(response.data['data']==false){
                         axiosThis.bankpasserror="Invalid password"
                         axiosThis.errorbankpass=true
                          return false
                       }else if(response.data['data']==true){
                                axiosThis.errorbankpass=false
                                axiosThis.bankpasserror=''
                                 //axiosThis.disableincome=!axiosThis.disableincome
                                 return true
                       }else{
                           axiosThis.bankpasserror='Password required'
                            return true
                       }
                      })
                      .catch(function (error) {
                        console.log(error);
                        axiosThis.cardloader=false;
                        alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                      });
                }
            },

        fileUpload(){
                        this.$v.$touch()
                        if (this.panFile  || this.bankFile || this.photoFile || this.signFile || this.incomeproofFile)
                        { 
                          this.cardloader = true;
                          if(this.cardloader){
                            //(this.aadharFile['type'] == 'image/jpeg' || this.aadharFile['type'] == 'image/jpg' || this.aadharFile['type'] == 'image/png' || this.aadharFile['type'] == 'application/pdf') &&
                            if( (this.panFile && (this.panFile['type'] == 'image/jpeg' || this.panFile['type'] == 'image/jpg' || this.panFile['type'] == 'image/png' || this.panFile['type'] == 'application/pdf'))
                             || (this.bankFile && (this.bankFile['type'] == 'image/jpeg' || this.bankFile['type'] == 'image/jpg' || this.bankFile['type'] == 'image/png' || this.bankFile['type'] == 'application/pdf') )
                             || (this.photoFile && (this.photoFile['type'] == 'image/jpeg' || this.photoFile['type'] == 'image/jpg' || this.photoFile['type'] == 'image/png' || this.photoFile['type'] == 'application/pdf')) 
                             || (this.signFile && (this.signFile['type'] == 'image/jpeg' || this.signFile['type'] == 'image/jpg' || this.signFile['type'] == 'image/png' || this.signFile['type'] == 'application/pdf'))
                             || (this.incomeproofFile && (this.incomeproofFile['type'] == 'application/pdf')) ){ 
                                this.$v.$reset()
                                var axiosThis = this
                                let formData = new FormData();
                               
                                if (this.panFile){formData.append('pancard', this.panFile);}
                                if (this.photoFile){formData.append('clientimage', this.photoFile);}
                                if (this.signFile){formData.append('signature', this.signFile);}
                                if (this.incomeproofFile){
                                  formData.append('financial', this.incomeproofFile);
                                  formData.append('financialPassword',this.incomepass);
                                  }
                                if (this.bankFile){
                                  formData.append('bankproof', this.bankFile);
                                  formData.append('bankproofPassword',this.bankpass);
                                  }

                                formData.append('mob',atob(localStorage.getItem('hash')));
                                // You should have a server side REST API 
                                 axios.post(api.api_url+'/uploadSignupUserFiles/'+atob(localStorage.getItem('hash')),
                                    formData,{
                                      headers: {'Content-Type': 'multipart/form-data'}
                                    }
                                  ).then(function (response) {
                                    console.log("main response : ",response.data)
                                    if(response.data['msg']=="All Files Upload sucessfully"){
                                      axiosThis.cardloader=false
                                       axiosThis.$router.push({name:'ipv'})   
                                     }
                                  })
                                  .catch(function () {
                                    axiosThis.cardloader=false;
                                    console.log('FAILURE!!');
                                    alert("Error has occured in files upload, please refresh the page and try again. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                                  });
                            }
                             else{
                              // console.log("File Extension error")
                              this.cardloader=false;
                              this.toasttext = "Upload files with PDF or Image formate"
                              this.snackbar = true
                              this.$v.$reset()
                          }
                          }
                          else{
                              // console.log("Same File Name")
                              this.cardloader=false;
                              this.toasttext = "Don't upload file with same name"
                              this.snackbar = true
                              this.$v.$reset()

                          }
                        }
                     },
      
      },
      computed:{
                        photoFErrors () {
                                    const errors = []
                                   // console.log(this.$v.photoFile.$dirty,this.$v.photoFile.required);
                                    if (!this.$v.photoFile.$dirty) return errors
                                    !this.$v.photoFile.required && errors.push('Mandatory Field')
                                    !this.$v.photoFile.mustcheck && errors.push('Image size should be less than 4 MB!')
                                    return errors
                                    },
                        signFErrors() {
                                      const errors = []
                                      if (!this.$v.signFile.$dirty) return errors
                                      !this.$v.signFile.required && errors.push('Mandatory Field ')
                                      !this.$v.signFile.mustcheck && errors.push('Image size should be less than 4 MB!')
                                      return errors
                                     },
                        panfErrors() {
                                      const errors = []
                                      if (!this.$v.panFile.$dirty) return errors
                                      !this.$v.panFile.required && errors.push('Mandatory Field ')
                                      !this.$v.panFile.mustcheck && errors.push('Image size should be less than 4 MB!')
                                      return errors
                                    },
                        BankSelectErrors() {
                                      const errors = []
                                      if (!this.$v.BankProofselect.$dirty) return errors
                                      !this.$v.BankProofselect.required && errors.push('Mandatory Field ')
                                      return errors
                                     },
                        bankfErrors() {
                                      const errors = []
                                      if (!this.$v.bankFile.$dirty) return errors
                                      !this.$v.bankFile.required && errors.push('Mandatory Field ')
                                      !this.$v.bankFile.mustcheck && errors.push('PDF size should be less than 4 MB!')
                                      return errors
                                    },      
                      IncomeSelectErrors() {
                                      const errors = []
                                      if (!this.$v.IncomeProofselect.$dirty) return errors
                                      !this.$v.IncomeProofselect.required && errors.push('Mandatory Field ')
                                      return errors
                                     },    

                      IncomeFErrors() {
                                      const errors = []
                                      if (!this.$v.incomeproofFile.$dirty) return errors
                                      !this.$v.incomeproofFile.required && errors.push('Mandatory Field ')
                                      !this.$v.incomeproofFile.PDFCheckRule && errors.push('upload PDF file')
                                      !this.$v.incomeproofFile.mustcheck && errors.push('PDF size should be less than 4 MB!')
                                      return errors
                                     },    

                                     //this.incomeproofFile['type'] == 'application/pdf'
      },
  }
</script>