<template>
  <v-container fluid>
    <v-card class="mx-6 mb-15" v-if="!loginpage">
      <v-card-title primary-title>
        <div>
          <v-img  class="ml-n1 d-flex"  contain width="110" :src="require('@/assets/invezt.svg')"/>
        </div>
      </v-card-title>
    </v-card>

        <v-row v-if="loginpage" class="pt-md-10 mt-md-4">
      <v-col cols="12" sm="6" md="6" lg="7" class=" my-auto">
      </v-col>
      <v-col xs="12" sm="6" md="6" lg="5" class="colmsec my-auto">
        <div>
          <v-img class="logo" src="@/assets/invezt.svg" width="150px"></v-img>

          <v-card-text>
            <h6 class="expmsg font-weight-bold">Welcome to ZEBU</h6>
          </v-card-text>
          <v-col cols="11" lg="6">
            
              <v-text-field
                label="E-mail"
                v-model="admin_id"
                outlined
                required
                :error="errordata"
                dense
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="admin_pass"
                :type="passwordeye ? 'text' : 'password'"
                :append-icon="passwordeye ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordeye = !passwordeye"
                 :error="errordata"
                outlined
                required
                dense
              ></v-text-field>
              <v-btn @click="clickitfunction()" color="primary" rounded> Sign In </v-btn>
           
          </v-col>
          <v-card-text class="temcdc">
          </v-card-text>
          <v-card-text>
            <v-divider class="line" color="gray" link></v-divider>
            <p class="about">
              Zebu Share and Wealth Managements Pvt. Ltd.<br />
              NSE / BSE / MCX , SEBI Registration No: INZ000174634<br />
              CDSL : 12080400<br />
              AMFI ARN : 113118<br />
              Research Analyst : INH200006044
            </p>
          </v-card-text>
        </div>
      </v-col>
        </v-row>

    <v-row v-if="!loginpage" class="mx-8 mb-5">
          <!-- <v-card> -->
            <v-spacer></v-spacer>
              <v-col cols=4>  
                <v-text-field class=""
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                ></v-text-field></v-col>
                <v-col cols=12>
                  <v-data-table fixed-header
                    :headers="headers"
                    :items="userdata"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                    >
                    <template v-slot:[`item.datetime`]="{ item }" >
                                {{item.datetime? (item.datetime.slice(0, 10)):''}}
                    </template>
                    <template v-slot:[`item.ipv_file_location`]="{ item }" >
                                <a class="text-decoration-none" :href="`${item.ipv_file_location?ipvurl+item.ipv_file_location:''}`" target="_blank">{{item.ipv_file_location? item.ipv_file_location.slice(42, 57):''}}</a>
                    </template>
                </v-data-table>
        </v-col>    
          <!-- </v-card> -->
    </v-row
  ></v-container>
</template>
<script>
import axios from 'axios';
import api from '@/api';
export default {
  data() {
    return {
      headers: [
          { text: 'mobileNumber',sortable: false,value: 'mobile' },
          { text: 'status',value: 'changes' },
          { text: 'Date',value: 'datetime', width:'130px' },
          { text: 'amount',value: 'amount'},
          { text: 'Payment ID',value: 'payment_id' },
          { text: 'payment status',value: 'payment_status' },
          { text: 'IPV Location',value: 'ipv_location', width:'250px' },
          { text: 'IPV Video File ',value: 'ipv_file_location', width:'250px' },
          {text:'ipv otp',value:'ipv_otp'} 
        ],
         ipvurl:"https://ekyc.invezt.in/",
         search: '',
         admin_id: "",
         admin_pass:"",
        passwordeye: false,
      userdata:[],
      loginpage:true,
      valid: true,
      mobile: '',
      errordata: false,
      password: '',
    
    };
  },
  methods:{
         

    clickitfunction () {
        if (this.admin_id && this.admin_pass) {
            var axios = require("axios");
            var data = JSON.stringify({
              email_id: this.admin_id,
              pass: this.admin_pass,
            });
            var axiosThis = this;
            var config = {
              method: "post",
              url: "https://awsbe.zebull.in/adminloginaiu",  //https://awsbe.zebull.in 
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                console.log("RESPONCE OF SIGIN IN", response.data);
                var login = response.data.msg;
                if (login === "login success") {
                  axiosThis.loginpage = false;
                  sessionStorage.setItem("admin_id", response.data.authcode);
                  axiosThis.getdetailsofuser()
                } else {
                  axiosThis.loginpage = true;
                  axiosThis.errordata = true;
                }
              })
              .catch(function (error) {
                console.log(error);
                alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
              });
        } else {
          this.errordata = true;
        }
    },
      getdetailsofuser(){
                 var hash = new Date().toLocaleString()
                        var axiosThis = this
                        localStorage.setItem('sesslog',btoa(hash))
                        var config = {
                          method: 'post',
                          url: api.api_url+'/getuserdetails?mob='+atob(localStorage.getItem('hash')),
                          headers: { }
                        };
                        axios(config)
                        .then(function (response) {
                              console.log("response data : ",response.data)
                              axiosThis.userdata=response.data
                              axiosThis.cardloader=false
                        })
                        .catch(function (error) {
                          console.log(error);
                          axiosThis.cardloader=false
                          alert("Server is not reachable, please try again later. \n For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
                        });
                }
  },

        mounted(){
                    window.history.forward();
                    window.history.pushState(null, "", window.location.href);
                    window.onpopstate = function () {
                    window.history.pushState(null, "", window.location.href);
                    }
                      var data=sessionStorage.getItem("admin_id");
                      if(data){
                        this.loginpage=false;
                        this.getdetailsofuser()
                      }
                      else{
                        this.loginpage=true;
                      }         
          }
};
</script>